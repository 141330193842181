import React, {Component} from 'react';
import * as d3 from 'd3';

import styles from './style.module.scss';

class Construction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leftOn: true
    };

    this.startBlinking = this.startBlinking.bind(this);
  }

  componentDidMount() {
    this.startBlinking();
  }

  startBlinking() {
    d3.interval(elapsed => {
      const { leftOn } = this.state;
      if (leftOn) {
        this.setState({
          leftOn: false
        });
      } else {
        this.setState({
          leftOn: true
        });
      }
    }, 600);
  }

  render() {
    const { leftOn } = this.state;
    return (
      <svg x="0px" y="0px" viewBox="0 0 1000 1000" className={styles.svg}>
        <g id="Layer_1">
          <rect x="322.4" y="613" className={styles.st0} width="528" height="43"/>
          <polygon className={styles.st1} points="215.4,309 263.4,309 399.4,777 351.4,777 	"/>
          <rect x="135.4" y="613" className={styles.st2} width="528" height="43"/>
          <polygon className={styles.st1} points="263.4,309 215.4,309 79.4,777 127.4,777 	"/>
          <polygon className={styles.st1} points="784.4,309 736.4,309 600.4,777 648.4,777 	"/>
          <polygon className={styles.st1} points="736.6,309 784.6,309 920.6,777 872.6,777 	"/>
          <g>
            <polygon className={styles.st3} points="734.1,574 96.1,574 122.6,467 760.6,467 		"/>
            <g>
              <defs>
                <polygon id="SVGID_1_" points="734.1,574 96.1,574 122.6,467 760.6,467 				"/>
              </defs>
              <clipPath id="SVGID_2_">
                <use href="#SVGID_1_" style={{overflow: "visible"}} />
              </clipPath>
              <g className={styles.st4}>
                <polygon className={styles.st5} points="-443.4,957.3 772.8,-17.7 795.7,12.7 -420.5,987.7 				"/>
                <polygon className={styles.st5} points="-366.5,989.9 849.8,14.9 872.6,45.3 -343.6,1020.3 				"/>
                <polygon className={styles.st5} points="-289.6,1022.5 926.7,47.5 949.6,77.9 -266.7,1052.9 				"/>
                <polygon className={styles.st5} points="-212.6,1055.1 1003.6,80.1 1026.5,110.5 -189.7,1085.5 				"/>
                <polygon className={styles.st5} points="-135.7,1087.7 1080.6,112.7 1103.4,143.1 -112.8,1118.1 				"/>
                <polygon className={styles.st5} points="-58.8,1120.3 1157.5,145.3 1180.4,175.7 -35.9,1150.7 				"/>
              </g>
            </g>
          </g>
          <g>
            <polygon className={styles.st3} points="764.1,454 126.1,454 152.6,347 790.6,347 		"/>
            <g>
              <defs>
                <polygon id="SVGID_3_" points="764.1,454 126.1,454 152.6,347 790.6,347 				"/>
              </defs>
              <clipPath id="SVGID_4_">
                <use href="#SVGID_3_" style={{overflow: "visible"}} />
              </clipPath>
              <g className={styles.st6}>
                <polygon className={styles.st5} points="-413.4,837.3 802.8,-137.7 825.7,-107.3 -390.5,867.7 				"/>
                <polygon className={styles.st5} points="-336.5,869.9 879.8,-105.1 902.6,-74.7 -313.6,900.3 				"/>
                <polygon className={styles.st5} points="-259.6,902.5 956.7,-72.5 979.6,-42.1 -236.7,932.9 				"/>
                <polygon className={styles.st5} points="-182.6,935.1 1033.6,-39.9 1056.5,-9.5 -159.7,965.5 				"/>
                <polygon className={styles.st5} points="-105.7,967.7 1110.6,-7.3 1133.4,23.1 -82.8,998.1 				"/>
                <polygon className={styles.st5} points="-28.8,1000.3 1187.5,25.3 1210.4,55.7 -5.9,1030.7 				"/>
              </g>
            </g>
          </g>
          <circle className={leftOn ? styles.st7 : styles.st8} cx="236.4" cy="273" r="50"/>
          <circle className={!leftOn ? styles.st7 : styles.st8} cx="760.6" cy="273" r="50"/>
        </g>
      </svg>
    );
  }
}

export default Construction;
