import React from 'react';

import styles from './style.module.scss'

export default class ChubbyEmu extends React.Component {
  constructor() {

  }

  render() {
    
    return (
      <></>
    );
  }
}