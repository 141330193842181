import React from 'react';
import { CircularProgress } from '@mui/material';
import { Redirect } from "react-router-dom";

import { WSConnection } from "../websocket";
import { formatTime, BackButton } from "../helpers";
import styles from "./style.module.scss";

export default class QueuePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queuedUp: false,
      inControl: false,
      posInLine: 0,
      timeUntilControl: 50,
      remainingTime: 50,
    };
  }

  componentDidMount() {
    WSConnection.initialize();
    WSConnection.bindListener(this);

    WSConnection.sendMessage({
      action: "joinQueue",
      queueName: this.props.queueName,
    });
  }

  wsOpened = () => {
    WSConnection.sendMessage({
      action: "joinQueue",
      queueName: this.props.queueName,
    });
  }

  messageReceived = (msg) => {
    if (msg?.inControl === true) {
      this.setState({
        inControl: true,
      });
    }
    else if (msg?.inControl === false) {
      this.setState({
        inControl: false,
        redirect: true,
      });
    }
    else if (msg?.remainingTime) {
      this.setState({
        remainingTime: msg.remainingTime,
        inControl: true,
      });
    }
    else if (msg?.totalInFront) {
      this.setState({
        queuedUp: true,
        inControl: false,
        posInLine: msg.totalInFront,
        timeUntilControl: msg.timeUntilControl,
      });
    }
  }

  errorThrown = (error) => {
    this.setState({
      error,
    });
  }

  generateNth(num) {
    const numStr = String(num);
    if (numStr.endsWith("1") && (num < 10 || num > 20)) {
      return "st";
    }
    if (numStr.endsWith("2") && (num < 10 || num > 20)) {
      return "nd";
    }
    if (numStr.endsWith("3") && (num < 10 || num > 20)) {
      return "rd";
    }
    return "th";
  }
  
  render() {
    const {title, image, name} = this.props;
    const {inControl, remainingTime, redirect, error, posInLine, queuedUp, timeUntilControl} = this.state;

    return inControl ? (<>
      {redirect ? <Redirect to="/halloween?msg=controlEnded"/> : null}
      <div className={styles.queueHeader}>
        <img src={image} className={styles.queueIcon} alt="control icon" />
        <div className={styles.queueTitle}>{title}</div>
        <img src={image} className={styles.queueIcon} alt="control icon"/>
      </div>
      <div className={styles.queueHeaderSpacer}></div>
      {this.props.children}
      <div className={styles.queueFooter}>
        <div className={styles.queueFooterInner}>You're&nbsp;<span className={styles.queueFooterNow}>now in control</span>&nbsp;for&nbsp;<span className={styles.queueFooterNum}>{remainingTime}</span>&nbsp;second{remainingTime > 1 ? "s" : ""}.</div>
        <div className={styles.queueFooterBack}>
          <BackButton mini/>
        </div>
      </div>
    </>) : queuedUp ? (<>
      <div className={styles.queueWaiting}>
        <div className={styles.queueWaitingTitle}>You're&nbsp;<span className={styles.queueWaitingPos}>{posInLine}{this.generateNth(posInLine)}</span>&nbsp;in line for:</div>
        <div className={styles.queueWaitingItem}>
          <img src={image}></img>
          <div>{name}</div>
        </div>
        <div className={styles.queueWaitingRemaining}>Only&nbsp;<span className={styles.queueWaitingTime}>{formatTime(timeUntilControl)}</span>&nbsp;until it's your turn!</div>
        <BackButton/>
      </div>
    </>) : (
      <div className={styles.queueLoading}>
        {redirect ? <Redirect to="/halloween?msg=controlEnded"/> : null}
        <CircularProgress/>
        <h1>Getting in line...</h1>
      </div>
    );
  }
}