import React, {Component} from 'react';
import { Card } from '@mui/material';

import styles from './style.module.scss';

export default class ItemCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  render() {
    const { headStyle, bodyStyle, className, title, children } = this.props;

    return (
      <Card className={`${styles.card} ${className}`}>
        <div style={headStyle ? headStyle : null} className={styles.title}>{title}</div>
        <hr className={styles.hr}/>
        <div style={bodyStyle ? bodyStyle : null} className={styles.contents}>{children}</div>
      </Card>
    );
  }
}