import { Popover } from "@mui/material";
import React from "react";

import styles from "./style.module.scss";

export default function Speechbox({open, anchorEl, onClose, children}) {
  return open && (
  <Popover 
    open={open} 
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
  >
    <div className={styles.speechbox}>
      {children}
    </div>
  </Popover>);
}