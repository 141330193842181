import React from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/lab/Alert';
import { Redirect } from 'react-router-dom';

import {SetFavicon} from 'utils';
import ContactForm from './ContactForm';
import BackToHome from 'components/BackToHome';
import style from './style.module.scss';
import Modal from 'components/Modal';

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      finished: false,
      loading: false,
      recaptchaDone: false,
      notification: {
        open: false,
      },
    };

    this.onSubmit = this.onSubmit.bind(this);

    SetFavicon();
  }
  
  onSubmit = values => {
    this.setState({
      loading: true,
    });

    fetch("https://api.jothedev.com/prod/contact", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values)
    })
    .then(response => response.json())
    .then(json => {
      if (json) {
        if (json.success) {
          return this.setState({
            loading: false,
            finished: true,
          });
        }
      }
      else {
        //failed
        this.setState({
          notification: {
            message: 'Failed to send your message. Try again?',
            type: 'error',
            open: true,
            onClose: () => {
              this.setState({
                notification: {
                  open: false,
                },
              });
            },
          },
          loading: false,
        });
      }
    });
  }
  
  closeModal = () => {
    this.setState({
      redirect: true,
    });
  }
  
  render() {
    const message = new URL(window.location.href).searchParams.get("message");
    const { loading, finished, notification, redirect } = this.state;
    return (redirect ? (<Redirect to="/"/>) : (
      <div className={`centeredContainer ${style.container}`}>
        <div>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={notification.open}
            autoHideDuration={6000}
            onClose={notification.onClose}
          >
            <MuiAlert elevation={6} variant="filled" onClose={notification.onClose} severity={notification.type}>
              {notification.message}
            </MuiAlert>
          </Snackbar>
          <Modal
            title="Message sent!"
            visible={finished}
            buttons={[
              {
                text: "Back to Home",
                onClick: this.closeModal,
              }
            ]}
            onClose={this.closeModal}
          >
            <p>Your message has been successfully sent to Jo the Dev! Thank you!</p>
          </Modal>
          <BackToHome align="left"/>
          <h1 className={style.h1}>Contact Me</h1>
          <ContactForm onSubmit={this.onSubmit} loading={loading} message={message} initialValues={{message: message}}/>
        </div>
      </div>
    ));
  }
}