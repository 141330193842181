import React from 'react';
import {TextField} from '@mui/material';
import {makeStyles, styled, createTheme, ThemeProvider} from '@mui/material/styles';

import DataTable from './DataTable';
import styles from './style.module.scss';
import icon from './favicon.png';

export default class SeatGuru extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fakeData: [
        {
          name: "Juan Carlos",
          age: 420,
          gender: "male",
          handedness: "right",
          politicalAffilication: "democrat",
          deadInside: true
        },
        {
          name: "Maria Reay",
          age: 69,
          gender: "female",
          handedness: "left",
          politicalAffilication: "republican",
          deadInside: false
        },
        {
          name: "John Smith",
          age: 666,
          gender: "male",
          handedness: "right",
          politicalAffilication: "conservative",
          deadInside: false
        },
        {
          name: "Benjamin Franklin",
          age: 89,
          gender: "male",
          handedness: "right",
          politicalAffilication: "liberal",
          deadInside: true
        },
        {
          name: "Patty Saint Cake",
          age: 32,
          gender: "female",
          handedness: "left",
          politicalAffilication: "unaffiliated",
          deadInside: false
        },
      ],
      fakeSchema: [
        {
          id: 'name',
          label: 'Name',
          type: 'freetext',
        },
        {
          id: 'age',
          label: 'Age',
          type: 'number',
        },
        {
          id: 'gender',
          label: 'Sex',
          type: 'choice',
          choices: [ "Female", "Male"],
        },
        {
          id: 'handedness',
          label: 'Handedness',
          type: 'choice',
          choices: ["Left", "Right", "Ambidextrous"],
        },
        {
          id: 'politicalAffilication',
          label: 'Political Affiliation',
          type: 'freetext',
        },
        {
          id: 'deadInside',
          label: 'Dead Inside?',
          type: 'boolean'
        }
      ],
    };
    this.testRef = React.createRef();
  }

  render() {
    const theme = createTheme({
      palette: {
        primary: {
          light: '#D62525',
          main: '#D62525',
          dark: '#D62525',
          contrastText: '#fff',
        },
        secondary: {
          light: '#D62525',
          main: '#D62525',
          dark: '#D62525',
          contrastText: '#000',
        },
      },
    });
    const TextBox = styled(TextField)({
      '& .MuiFormLabel-root': {
        //color: '#ffffff60',
      },
      '& .MuiInput-underline:before': {
        //borderBottomColor: '#ffffff60',
        '&:hover': {
        //  borderBottomColor: '#ffffff60',
        },
      },
      '& .MuiInputBase-root': {
       // color: 'white',
       // background: '#ffffff20',
        '& fieldset': {
       //   borderColor: 'white',
        },
        '&:hover fieldset': {
       //   borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
       //   borderColor: 'white',
        },
      },
    });
    const GiantTextBox = styled(TextBox)({
      '& .MuiInputBase-root': {
        fontSize: 60,
      },
    });
    /*const DataTable = styled(DataGrid)({
      '& ': {
        color: 'white',
        background: '#333',
      },
      '& .MuiCheckbox-root': {
        color: 'white',
      },
      '& .MuiSvgIcon-root': {
        color: 'white'
      },
      '& .MuiTypography-root': {
        color: 'white'
      }
    });*/

    const headers = [
      { field: 'name', headerName: 'Name', width: 150, },
      { field: 'age', type: 'number',headerName: 'Age', width: 90, },
      { field: 'gender', headerName: 'Sex', width: 100, },
      { field: 'handedness', headerName: 'Handedness', width: 200, },
      { field: 'politicalAffilication', headerName: 'Political Party', width: 200, },
      { field: 'deadInside', headerName: 'Dead Inside?', width: 150, },
    ];
    const foodData = [
      {
        name: 'Cupcake', 
        calories: 305,
        fat: 3.7,
        carbs: 67,
        protein: 4.3
      },
      {
        name: 'Donut', 
        calories: 452,
        fat: 25.0,
        carbs: 51,
        protein: 4.9
      },
      {
        name: 'Eclair', 
        calories: 262,
        fat: 16.0,
        carbs: 24,
        protein: 6.0
      },
      {
        name: 'Frozen yoghurt', 
        calories: 159,
        fat: 6.0,
        carbs: 24,
        protein: 4.0
      },
      {
        name: 'Gingerbread', 
        calories: 356,
        fat: 16.0,
        carbs: 49,
        protein: 3.9
      },
      {
        name: 'Honeycomb', 
        calories: 408,
        fat: 3.2,
        carbs: 87,
        protein: 6.5
      },
      {
        name: 'Ice cream sandwich', 
        calories: 237,
        fat: 9.0,
        carbs: 37,
        protein: 4.3
      },
      {
        name: 'Jelly Bean', 
        calories: 375,
        fat:  0.0,
        carbs: 94,
        protein: 0.0
      },
      {
        name: 'KitKat', 
        calories: 518,
        fat: 26.0,
        carbs: 65,
        protein: 7.0
      },
      {
        name: 'Lollipop', 
        calories: 392,
        fat: 0.2,
        carbs: 98,
        protein: 0.0
      },
      {
        name: 'Marshmallow', 
        calories: 318,
        fat: 0.8,
        carbs: 1,
        protein: 2.0
      },
      {
        name: 'Nougat', 
        calories: 360,
        fat: 19.0,
        carbs: 9,
        protein: 37.0
      },
      {
        name: 'Oreo', 
        calories: 437,
        fat: 18.0,
        carbs: 63,
        protein: 4.0
      },
    ];

    const {fakeData, fakeSchema} = this.state;
    return (
      <ThemeProvider theme={theme}>
      <div className={styles.container}>
        <div className={styles.header}>
          <img src={icon}/>
          <h1>SeatGuru</h1>
        </div>
        {/*<div className={styles.none}>
          It's pretty empty in here...
          <button className={`${styles.button}`}>Add a class</button>
        </div>*/}
        <div className={styles.body}>
          <div className={styles.edit}>
            <GiantTextBox label="Class Name" variant="filled"
              inputRef={this.testRef}
              onChange={() => {console.log(this.testRef.current.value )}}
            />
            <h2>Student List</h2>
            <DataTable
              data={fakeData}
              schema={fakeSchema}
              title="Students"
            />
            {/*<DataTable 
              rows={fakeData}
              columns={headers}
              pageSize={20}
              checkboxSelection
              className={styles.studentList}
            />*/}
          </div>      
        </div>
      </div>
      </ThemeProvider>
    );
  }
}