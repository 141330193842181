import React, { Component } from 'react';

import styles from './style.module.scss';

class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      crossed: false,
    };
  };

  onClick = () => {
    const { crossed } = this.state;
    const { onChange } = this.props;
    if (crossed) {
      this.setState({
        crossed: false
      });
      onChange(false);
    } else {
      this.setState({
        crossed: true
      });
      onChange(true);
    }
  }

  render() {
    const { text } = this.props;
    const { crossed } = this.state;
    return (!crossed ? (
        <h2 onClick={this.onClick} className={styles.item}>{text}</h2>
      ) : (
        <h2 onClick={this.onClick} className={styles.item_del}><del>{text}</del></h2>
      )
    )
  }
}

export default Item;