import React from 'react';
import {SubtitlesRounded, TuneRounded, MovieRounded, GetAppRounded} from '@mui/icons-material';
import Icon from '@mdi/react';
import { mdiAutoFix } from '@mdi/js';

import BackToHome from 'components/BackToHome';
import styles from './style.module.scss';
import logo from './favicon.png';

export default function MovieMute() {
  return (
    <div className={`centeredContainerHorz ${styles.container}`}>
      <BackToHome align="left" width="70vw"/>
      <div className={styles.header}>
        <img alt="MovieMute icon" src={logo}/>
        <h1>MovieMute</h1>
      </div>
      <div className={styles.body}>
        <h2>Tired of actors <span className={styles.yellow}>bleeping it up</span> on screen?</h2>
        <h3>Here's how it works:</h3>
        <div className={styles.inlineIcon}>
          <SubtitlesRounded/>
          <h4>Auto <span className={styles.yellow}>subtitle</span> scanning</h4>
        </div>
        <p>Import your movie file into your library, and MovieMute will automatically lookup the movie's info and download the subtitles for it.
          Then, it scans the subtitles for swear words.</p>
        <div className={styles.inlineIcon}>
          <Icon path={mdiAutoFix} size={1} color="gold"/>
          <h4>Smart <span className={styles.yellow}>audio analysis</span></h4>
        </div>
        <p>MovieMute will then analyze the audio of the movie to find the exact timings of each swear word.</p>
        <div className={styles.inlineIcon}>
          <TuneRounded/>
          <h4><span className={styles.yellow}>Customizable</span> filter settings</h4>
        </div>
        <p>Once MovieMute's finished processing your movie, you can tailor your movie to your preferences by choosing which swear words to filter out.</p>
        <div className={styles.inlineIcon}>
          <MovieRounded/>
          <h4>Watch and <span className={styles.yellow}>enjoy</span>!</h4>
        </div>
        <p>Start watching the movie in-app, and MovieMute will <span className={styles.yellow}>mute</span> the bad words!</p>
        <div className={styles.footer}>
          <div className={styles.card}>
            <div className={styles.inlineIcon}>
              <GetAppRounded/>
              <h1>WINDOWS DOWNLOAD</h1>
            </div>
            <div className={styles.coming}>
              <h1>Coming soon!</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}