import React from 'react';
import { Link } from 'react-router-dom';

import Construction from 'components/Construction';

import styles from './style.module.scss';

export default function UnderConstruction() {
  return (
    <div class="centeredContainer">
      <Construction />
      <div className={styles.text}>
        <h2 className={styles.h2}>This page is under construction.</h2>
        <h3 className={styles.h3}><Link to="/">Wanna go back?</Link></h3>
      </div>
    </div>
  );
}