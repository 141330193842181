import React from "react"
import { HomeRounded, ArrowForwardRounded, ArrowBackRounded } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import * as d3 from 'd3';
import {Helmet} from "react-helmet";

import {SetFavicon} from 'utils';
import style from './style.module.scss';
import profilePic from './img/profile pic.jpg';
import adobe from './img/adobe.svg';
import aws from './img/aws.svg';
import csharp from './img/csharp.svg';
import dotnet from './img/dotnet.svg';
import js from './img/js.svg';
import php from './img/php.svg';
import react from './img/react.svg';
import testout from './img/testout.svg';
import office from './img/office.svg';
import fbla from './img/fbla.svg';
import microsoft from './img/microsoft.svg';
import linkedin from './img/linkedin.svg';
import github from './img/github.svg';

import BackToHome from 'components/BackToHome';

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
    }
  }

  componentDidMount() {
    SetFavicon();

    this.pageOne();
    d3.select("#goHome")
      .transition()
      .delay(500)
      .duration(1000)
      .style("opacity", 1);
  }

  pageOne() {
    if (this.state.page === 2) {
      d3.select(`#whatTop`)
        .transition()
        .duration(1000)
        .style("transform", 'translate(-150%, 0px)');

      d3.select(`#whatBottom`)
        .transition()
        .delay(250)
        .duration(1000)
        .style("transform", 'translate(110%, 0px)');

      d3.select(`#whatBottomMobile`)
        .transition()
        .duration(1000)
        .style("transform", "rotateZ(30deg) translate(110%, -300px)");

      setTimeout(() => {
        d3.select('#whatWrapper')
          .style("display", "none");
      }, 1250);
    }
    else {
      d3.select(`#whoTop`)
        .transition()
        .duration(1000)
        .style("transform", 'translate(-10%, 0px)');

      d3.select(`#whoBottom`)
        .transition()
        .delay(250)
        .duration(1000)
        .style("transform", 'translate(-10%, 0px)');

      d3.select(`#whoBottomMobile`)
        .transition()
        .duration(1000)
        .style("transform", "rotateZ(0deg) translate(0%, 0px)");
    }
    this.setState({page: 1});
  }

  pageTwo() {
    if (this.state.page === 3) {
      d3.select(`#achTop`)
        .transition()
        .duration(1000)
        .style("transform", 'translate(-150%, 0px)');

      d3.select(`#achBottom`)
        .transition()
        .delay(250)
        .duration(1000)
        .style("transform", 'translate(110%, 0px)');

      d3.select(`#achBottomMobile`)
        .transition()
        .delay(250)
        .duration(1000)
        .style("transform", 'rotateZ(30deg) translate(110%, -300px)');

      setTimeout(() => {
        d3.select('#achWrapper')
          .style("display", "none");
      }, 1250);
    }
    else {
      d3.select('#whatWrapper')
        .style("display", "block");

      d3.select(`#whatTop`)
        .transition()
        .duration(1000)
        .style("transform", 'translate(-10%, 0px)');

      d3.select(`#whatBottom`)
        .transition()
        .delay(250)
        .duration(1000)
        .style("transform", 'translate(-10%, 0px)');

      d3.select(`#whatBottomMobile`)
        .transition()
        .duration(1000)
        .style("transform", "rotateZ(0deg) translate(0%, 0px)");
    }
    
    
    this.setState({page: 2});
  }

  pageThree() {
    if (this.state.page === 4) {
      d3.select('#moreWrapper')
        .style("display", "block");

      d3.select(`#moreTop`)
        .transition()
        .duration(1000)
        .style("transform", 'translate(-150%, 0px)');

      d3.select(`#moreBottom`)
        .transition()
        .delay(250)
        .duration(1000)
        .style("transform", 'translate(110%, 0px)');

      d3.select(`#moreBottomMobile`)
        .transition()
        .delay(250)
        .duration(1000)
        .style("transform", 'rotateZ(30deg) translate(110%, -300px)');

      setTimeout(() => {
        d3.select('#moreWrapper')
          .style("display", "none");
      }, 1250);
    }
    else {
      d3.select('#achWrapper')
        .style("display", "block");

      d3.select(`#achTop`)
        .transition()
        .duration(1000)
        .style("transform", 'translate(-10%, 0px)');

      d3.select(`#achBottom`)
        .transition()
        .delay(250)
        .duration(1000)
        .style("transform", 'translate(-10%, 0px)');

      d3.select(`#achBottomMobile`)
        .transition()
        .delay(250)
        .duration(1000)
        .style("transform", 'rotateZ(0deg) translate(0%, 0px)');
    }    

    this.setState({page: 3});
  }

  pageFour() {
    this.setState({page: 4});

    d3.select('#moreWrapper')
      .style("display", "block");

    d3.select(`#moreTop`)
      .transition()
      .duration(1000)
      .style("transform", 'translate(-10%, 0px)');

    d3.select(`#moreBottom`)
      .transition()
      .delay(250)
      .duration(1000)
      .style("transform", 'translate(-10%, 0px)');

    d3.select(`#moreBottomMobile`)
      .transition()
      .delay(250)
      .duration(1000)
      .style("transform", 'rotateZ(0deg) translate(0%, 0px)');
  }

  render() {
    return (
      <>
        <BackToHome className={style.goHome} id="goHome" style={{opacity: 0}}/>
        <Helmet title="About"/>
        <div className={style.wrapper}>
          <div className={`centeredContainer ${style.containerMobile}`} style={{overflow: "hidden"}}>
            <div className={style.rotateTop}>
              <div id="whoTop" style={{transform: "translate(-150%, 0px)"}} className={`${style.topBar} ${style.whoGradient}`}>
                <h1 className={style.who}>Who I Am</h1>
              </div> 
            </div>
            <div className={style.rotateBottom}>
              <div id="whoBottom" style={{transform: "translate(110%, 0px)"}} className={`${style.bottomBar} ${style.whoGradient}`}>
                <div className={style.whoInfo}>
                  <span className={style.profileContainer}>
                    <img className={style.profilePic} alt="Me" src={profilePic}/>
                  </span>
                  <span>
                    <h2 className={style.whoName}>Jordan Skousen</h2>
                    <h2 className={style.whoAbout}>Software and Web Developer from Utah USA <img className={style.americanFlag} alt="USA Flag" src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"/></h2>
                  </span>
                  <span className={style.nextContainer}>
                    <button className={style.next} onClick={() => this.pageTwo()}><ArrowForwardRounded /></button>
                  </span>
                </div>
              </div>
            </div>
            <div className={style.rotateBottomMobile}>
              <div id="whoBottomMobile" style={{transform: "rotateZ(30deg) translate(110%, -300px)"}} className={`${style.bottomBar} ${style.whoGradient}`}>
                <div className={style.whoInfo}>
                  <span className={style.profileContainer}>
                    <img className={style.profilePic} alt="Me" src={profilePic}/>
                  </span>
                  <span>
                    <h2 className={style.whoName}>Jordan Skousen</h2>
                    <h2 className={style.whoAbout}>Software and Web Developer from Utah USA <img className={style.americanFlag} alt="Flag of the United States of America" src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"/></h2>
                  </span>
                  
                </div>
                <span className={style.nextContainer}>
                    <button className={style.next} onClick={() => this.pageTwo()}><ArrowForwardRounded /></button>
                  </span>
              </div>
            </div>
          
          </div>
        </div>
        <div className={style.wrapper} id="whatWrapper" style={{display: "none"}}>
          <div className={`centeredContainer ${style.containerMobile}`} style={{overflow: "hidden"}}>
            <div className={style.rotateTop}>
              <div id="whatTop" style={{transform: "translate(-150%, 0px)"}} className={`${style.topBar} ${style.whatGradient}`}>
                <h1 className={style.who}>What I Do</h1>
              </div> 
            </div>
            <div className={style.rotateBottom}>
              <div id="whatBottom" style={{transform: "translate(110%, 0px)"}} className={`${style.bottomBar} ${style.whatGradient}`}>
                <div className={style.whatInfo}>
                  <span className={style.nextContainer}>
                    <button className={style.next} onClick={() => this.pageOne()}><ArrowBackRounded /></button>
                  </span>
                  <span className={style.languageContainer}>
                    <img className={style.languageBubble} src={csharp} alt="C# logo"/>
                    <p><b>C#</b> (WinForms and WPF)</p>
                  </span>
                  <span className={style.languageContainer}>
                    <img className={style.languageBubble} src={react} alt="ReactJS logo"/>
                    <p><b>ReactJS</b></p>
                  </span>
                  <span className={style.languageContainer}>
                    <img className={style.languageBubble} src={js} alt="JavaScript logo"/>
                    <p><b>JavaScript</b><br/>(+ Node.js)</p>
                  </span>
                  <span className={style.languageContainer}>
                    <img className={style.languageBubble} src={dotnet} alt=".NET logo"/>
                    <p><b>ASP.NET</b><br/>(+ Blazor)</p>
                  </span>
                  <span className={style.languageContainer}>
                    <img className={style.languageBubble} src={php} alt="PHP logo"/>
                    <p><b>PHP</b></p>
                  </span>
                  <span className={style.languageContainer}>
                    <img className={style.languageBubble} src={aws} alt="AWS logo"/>
                    <p style={{"font-size": "12px"}}><b>AWS</b><br/>(Lambdas, S3, DynamoDB, etc.)</p>
                  </span>
                  <span className={style.languageContainer}>
                    <img className={style.languageBubble} src={adobe} alt="Premiere, After Effects, Illustrator and Photoshop logos"/>
                    <p style={{"font-size": "11px"}}><b>Adobe Suite</b><br/>(After Effects, Illustrator, Photoshop, Premiere, etc.)</p>
                  </span>
                  <span className={style.nextContainer}>
                    <button className={style.next} onClick={() => this.pageThree()}><ArrowForwardRounded /></button>
                  </span>
                </div>
              </div>
            </div>
            <div className={style.rotateBottomMobile}>
              <div id="whatBottomMobile" style={{transform: "rotateZ(30deg) translate(110%, -300px)"}} className={`${style.bottomBar} ${style.whatGradient}`}>
                <div className={style.whatInfo}>
                  <div className={style.whatMobileRow1}>
                    <span className={style.languageContainer}>
                      <img className={style.languageBubble} src={csharp} alt="C# logo"/>
                      <p><b>C#</b> (WinForms and WPF)</p>
                    </span>
                    <span className={style.languageContainer}>
                      <img className={style.languageBubble} src={react} alt="ReactJS logo"/>
                      <p><b>ReactJS</b></p>
                    </span>
                    <span className={style.languageContainer}>
                      <img className={style.languageBubble} src={js} alt="JavaScript logo"/>
                      <p><b>JavaScript</b><br/>(+ Node.js)</p>
                    </span>
                  </div>
                  <div className={style.whatMobileRow2}>
                    <span className={style.languageContainer}>
                      <img className={style.languageBubble} src={dotnet} alt=".NET logo"/>
                      <p><b>ASP.NET</b><br/>(+ Blazor)</p>
                    </span>
                    <span className={style.languageContainer}>
                      <img className={style.languageBubble} src={php} alt="PHP logo"/>
                      <p><b>PHP</b></p>
                    </span>
                    <span className={style.languageContainer}>
                      <img className={style.languageBubble} src={aws} alt="AWS logo"/>
                      <p style={{"font-size": "12px"}}><b>AWS</b><br/>(Lambdas, S3, DynamoDB, etc.)</p>
                    </span>
                  </div>
                  <div className={style.whatMobileRow3}>
                    <span className={style.languageContainer}>
                      <img className={style.languageBubble} src={adobe} alt="Premiere, After Effects, Illustrator and Photoshop logos"/>
                      <p style={{"font-size": "11px"}}><b>Adobe Suite</b><br/>(After Effects, Illustrator, Photoshop, Premiere, etc.)</p>
                    </span>
                  </div>
                  <span className={style.nextContainer}>
                    <button className={style.next} onClick={() => this.pageOne()}><ArrowBackRounded /></button>
                    <button className={style.next} onClick={() => this.pageThree()}><ArrowForwardRounded /></button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.wrapper} id="achWrapper" style={{display: "none"}}>
          <div className={`centeredContainer ${style.containerMobile}`} style={{overflow: "hidden"}}>
            <div className={style.rotateTop}>
              <div id="achTop" style={{transform: "translate(-150%, 0px)"}} className={`${style.topBar} ${style.achGradient}`}>
                <h1 className={style.who}>What I've Achieved</h1>
              </div> 
            </div>
            <div className={style.rotateBottom}>
              <div id="achBottom" style={{transform: "translate(110%, 0px)"}} className={`${style.bottomBar} ${style.achGradient}`}>
                <div className={style.whatInfo}>
                  <span className={style.nextContainer}>
                    <button className={style.next} onClick={() => this.pageTwo()}><ArrowBackRounded /></button>
                  </span>
                  <span className={style.achievementContainer}>
                    <img className={style.achievementBubble} src={testout} alt="TestOut logo"/>
                    <p>TestOut Certified PC Pro</p>
                  </span>
                  <span className={style.achievementContainer}>
                    <img className={style.achievementBubble} src={office} alt="Microsoft Office logo"/>
                    <p>Microsoft Office 2013 Master</p>
                  </span>
                  <span className={style.achievementContainer}>
                    <img className={style.achievementBubble} src={fbla} alt="FBLA logo"/>
                    <p style={{"font-size": "10px"}}>2nd Place in Computer Science Category at FBLA Leadership Conference</p>
                  </span>
                  <span className={style.achievementContainer}>
                    <img className={style.achievementBubble} src={microsoft} alt="Microsoft logo"/>
                    <p style={{"font-size": "11px"}}>Microsoft Tech Associate for Software Development Fundamentals</p>
                  </span>
                  <span className={style.achievementContainer}>
                    <img className={style.achievementBubble} src={microsoft} alt="Microsoft logo"/>
                    <p style={{"font-size": "11px"}}>Microsoft Tech Associate for Windows OS Fundamentals</p>
                  </span>
                  <span className={style.nextContainer}>
                    <button className={style.next} onClick={() => this.pageFour()}><ArrowForwardRounded /></button>
                  </span>
                </div>
              </div>
            </div>
            <div className={style.rotateBottomMobile}>
              <div id="achBottomMobile" style={{transform: "rotateZ(30deg) translate(110%, -300px)"}} className={`${style.bottomBar} ${style.achGradient}`}>
                <div className={style.whatInfo}>
                  <div className={style.achMobileRow1}>
                    <span className={style.achievementContainer}>
                      <img className={style.achievementBubble} src={testout} alt="TestOut logo"/>
                      <p>TestOut Certified PC Pro</p>
                    </span>
                    <span className={style.achievementContainer}>
                      <img className={style.achievementBubble} src={office} alt="Microsoft Office logo"/>
                      <p>Microsoft Office 2013 Master</p>
                    </span>
                    <span className={style.achievementContainer}>
                      <img className={style.achievementBubble} src={fbla} alt="FBLA logo"/>
                      <p style={{"font-size": "10px"}}>2nd Place in Computer Science Category at FBLA Leadership Conference</p>
                    </span>
                  </div>
                  <div className={style.achMobileRow2}>
                    <span className={style.achievementContainer}>
                      <img className={style.achievementBubble} src={microsoft} alt="Microsoft logo"/>
                      <p style={{"font-size": "11px"}}>Microsoft Tech Associate for Software Development Fundamentals</p>
                    </span>
                    <span className={style.achievementContainer}>
                      <img className={style.achievementBubble} src={microsoft} alt="Microsoft logo"/>
                      <p style={{"font-size": "11px"}}>Microsoft Tech Associate for Windows OS Fundamentals</p>
                    </span>
                  </div>
                  <span className={style.nextContainer}>
                    <button className={style.next} onClick={() => this.pageTwo()}><ArrowBackRounded /></button>
                    <button className={style.next} onClick={() => this.pageFour()}><ArrowForwardRounded /></button>
                  </span>
                </div>
              </div>
            </div>
          
          </div>
        </div>
        <div className={style.wrapper} id="moreWrapper" style={{display: "none"}}>
          <div className={`centeredContainer ${style.containerMobile}`} style={{overflow: "hidden"}}>
            <div className={style.rotateTop}>
              <div id="moreTop" style={{transform: "translate(-150%, 0px)"}} className={`${style.topBar} ${style.moreGradient}`}>
                <h1 className={style.who}>More Links</h1>
              </div> 
            </div>
            <div className={style.rotateBottom}>
              <div id="moreBottom" style={{transform: "translate(110%, 0px)"}} className={`${style.bottomBar} ${style.moreGradient}`}>
                <div className={style.whatInfo}>
                  <span className={style.nextContainer}>
                    <button className={style.next} onClick={() => this.pageThree()}><ArrowBackRounded /></button>
                  </span>
                  <a href="https://www.linkedin.com/in/jo-the-dev" target="_blank" rel="noopener noreferrer">
                    <span className={style.linkContainer}>
                      <img className={style.linkBubble} src={linkedin} alt="LinkedIn logo"/>
                      <p>LinkedIn</p>
                    </span>
                  </a>
                  <a href="https://github.com/JordanSkousen" target="_blank" rel="noopener noreferrer">
                    <span className={style.linkContainer}>
                      <img className={style.linkBubble} src={github} alt="GitHub logo"/>
                      <p>GitHub</p>
                    </span>
                  </a>
                  <Link to="/projects">
                    <span className={style.linkContainer}>
                      <div className={style.projectsBubble}>
                        <span role="img" aria-label="test tube">🧪</span>
                      </div>
                      <p>Projects</p>
                    </span>
                  </Link>
                  <Link to="/contact">
                    <span className={style.linkContainer}>
                      <div className={style.projectsBubble}>
                        <span role="img" aria-label="contact card index">📇</span>
                      </div>
                      <p style={{"filter": "drop-shadow(2px 4px 2px black)"}}>Contact</p>
                    </span>
                  </Link>
                  <span className={style.nextContainer}>
                    <Link to="/" className={style.home}><HomeRounded /></Link>
                  </span>
                </div>
              </div>
            </div>
            <div className={style.rotateBottomMobile}>
              <div id="moreBottomMobile" style={{transform: "rotateZ(30deg) translate(110%, -300px)"}} className={`${style.bottomBar} ${style.moreGradient}`}>
                <div className={style.whatInfo}>
                  <div className={style.moreMobileRow1}>
                    <a href="https://www.linkedin.com/in/jo-the-dev" target="_blank" rel="noopener noreferrer">
                      <span className={style.linkContainer}>
                        <img className={style.linkBubble} src={linkedin} alt="LinkedIn logo"/>
                        <p>LinkedIn</p>
                      </span>
                    </a>
                    <a href="https://github.com/JordanSkousen" target="_blank" rel="noopener noreferrer">
                      <span className={style.linkContainer}>
                        <img className={style.linkBubble} src={github} alt="GitHub logo"/>
                        <p>GitHub</p>
                      </span>
                    </a>
                  </div>
                  <div className={style.moreMobileRow2}>
                    <Link to="/projects">
                      <span className={style.linkContainer}>
                        <div className={style.projectsBubble}>
                          <span role="img" aria-label="test tube">🧪</span>
                        </div>
                        <p>Projects</p>
                      </span>
                    </Link>
                    <Link to="/contact">
                      <span className={style.linkContainer}>
                        <div className={style.projectsBubble}>
                          <span role="img" aria-label="contact card index">📇</span>
                        </div>
                        <p style={{"filter": "drop-shadow(2px 4px 2px black)"}}>Contact</p>
                      </span>
                    </Link>
                  </div>
                  <span className={style.nextContainer}>
                    <button className={style.next} onClick={() => this.pageThree()}><ArrowBackRounded /></button>
                    <Link to="/" className={style.home}><HomeRounded /></Link>
                  </span>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </>
    )
  }
}

export default About;