import React from "react";
import { 
  CircularProgress,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Checkbox,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@mui/material";
import { ThemeProvider, createTheme, styled, makeStyles } from "@mui/material/styles";
import { 
  CloseRounded,
  Devices,
} from "@mui/icons-material";
import {
  mdiWeb,
  mdiApplicationCog,
} from '@mdi/js';
import Icon from '@mdi/react';
import UAParser from "ua-parser-js";

import { WSConnection } from "../websocket";
import {formatTime} from "../helpers";
import StainedGlass from "../assets/StainedGlass.png";
import { SetFavicon } from "utils";
import styles from "./style.module.scss";

const theme = createTheme({
  palette: {
    primary: {
      light: '#e88f1a',
      main: '#e88f1a',
      dark: '#e88f1a',
      contrastText: '#e88f1a',
    },
    secondary: {
      light: '#e43b3b',
      main: '#e43b3b',
      dark: '#e43b3b',
      contrastText: '#e88f1a',
    },
  },
});

const queueNames = [
  "PorchWindowLeft",
  "PorchWindowRight",
  "FrontDoorWindowTop",
  "FrontDoorWindowBottom",
  "GarageWindow",
  "Lanterns",
  "Lampposts",
  "Spiders",
  "Skeleton1",
  "Skeleton2",
  "Skeleton3",
  "Dragon"
];

const windows = [
  "PorchWindowLeft",
  "PorchWindowRight",
  "FrontDoorWindowTop",
  "FrontDoorWindowBottom",
  "GarageWindow",
];

function Controller(props) {
  const {controller, inControl, queue, snackbar, checked, onCheck} = props;
  const ua = controller?.userAgent ? UAParser(controller?.userAgent) : null;
  return (<>
    <div className={`${styles.controllerContainer} ${inControl ? styles.controllerInControl : ""}`} onClick={() => {
     /* WSConnection.sendMessage({
        ban: controller.ip,
      });
      snackbar("Banned IP");*/
    }}>
      <div style={{display: "flex"}}>
        <Checkbox checked={checked} onChange={onCheck} color="primary"/>
        <div onClick={onCheck}>
          <div className={styles.controllerId}>#{controller.id.substring(0, Math.min(controller.id.length, 8))}</div>
          {queue ? <div>{inControl ? "CONTROLLING:" : "IN QUEUE:"} <b>{queue}</b></div> : null}
          <div>IP: {controller.ip}</div>
          <div className={styles.controllerLineItem}>
            <Icon path={mdiWeb}/>
            {ua?.browser?.name || "(unknown)"} {ua?.browser?.major || "(unknown)"}
          </div>
          <div className={styles.controllerLineItem}>
            <Devices/>
            {ua?.device?.vendor || "(unknown)"} {ua?.device?.model || "(unknown)"}
          </div>
          <div className={styles.controllerLineItem}>
            <Icon path={mdiApplicationCog}/>
            {ua?.os?.name || "(unknown)"} {ua?.os?.version || "(unknown)"}
          </div>
          <div>Connected for <span className={styles.controllerTime}>{formatTime(Math.floor(controller.connectionLength / 1000))}</span></div>
        </div>
      </div>
    </div>
  </>);
}

export default class AdminPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      authed: false,
      queues: [],
      controllers: [],
      snackbar: null,
      error: null,
      checkedControllers: [],
    };
  }

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    WSConnection.initialize(`wss://websocket.jothedev.com/?api-key=${query.get("api-key")}`);//ws://localhost:8080/?api-key=${query.get("api-key")}`);//
    WSConnection.bindListener(this);
    SetFavicon();
  }

  wsOpened = () => {

  }

  messageReceived = (msg) => {
    if (msg?.connected) {
      if (msg.adminAuthorized) {
        this.setState({
          authed: true,
        });
      }
    }
    else if (msg?.queues && msg?.controllers) {
      this.setState({
        queues: msg.queues,
        controllers: msg.controllers,
        bans: msg.bans,
      });
    }
    else if (msg?.errorMessage) {
      this.setState({
        error: msg.errorMessage,
      });
    }
  }

  makeDisplay = (name, state) => {
    //WINDOWS
    if (windows.includes(name) && state) {
      const {width, height, upperCanvas, lowerCanvas} = JSON.parse(state);
      return (<div className={styles.window}>
        <img src={StainedGlass} width={width} height={height}/>
        <img src={upperCanvas} style={{visibility: upperCanvas ? "visible" : "hidden"}} className="upperCanvas" width={width} height={height}/>
        <img src={lowerCanvas} style={{visibility: lowerCanvas ? "visible" : "hidden"}} className="lowerCanvas" width={width} height={height}/>
      </div>);
    }
    //LANTERNS
    if (name === "Lanterns" && state) {
      return (<h2>{state}</h2>);
    }
    //LAMPPOSTS
    if (name === "Lampposts" && state) {
      return (<div style={{width: 200, height: 50, backgroundColor: `#${state.replace(/\"/g, "").replace("#", "")}`}}></div>);
    }
    //SPIDERS
    if (name === "Spiders" && state) {
      return (<h2>{state}</h2>);
    }
    //SKELETONS
    //DRAGON
    if (name === "Dragon" && state) {
      return (<h2>{state}</h2>);
    }
  }
  
  render() {
    const {authed, queues, controllers, bans, snackbar, error, checkedControllers} = this.state;
    return authed ? (<ThemeProvider theme={theme}>
      {checkedControllers.length > 0 ? <AppBar position="fixed">
        <Toolbar>
          <IconButton edge="start" onClick={() => this.setState({checkedControllers: []})}>
            <CloseRounded/>
          </IconButton>
          <h2 style={{flexGrow: 1, margin: 0}}>
            {checkedControllers.length} selected
          </h2>
          <Button onClick={() => {
            WSConnection.sendMessage({kick: checkedControllers});
            this.setState({
              snackbar: checkedControllers.length === 1 ? `Kicked controller #${checkedControllers[0].substring(0, 8)}` : `Kicked ${checkedControllers.length} controllers`,
              checkedControllers: [],
            });
          }}>
            KICK
          </Button>
          <Button color="secondary" onClick={() => {
            WSConnection.sendMessage({ban: checkedControllers});
            this.setState({
              snackbar: checkedControllers.length === 1 ? `Banned controller #${checkedControllers[0].substring(0, 8)}` : `Banned ${checkedControllers.length} controllers`,
              checkedControllers: [],
            });
          }}>
            BAN
          </Button>
        </Toolbar>
      </AppBar> : null}
      <div className={styles.container}>
        <h1>Halloween Admin</h1>
        {queues.length > 0 ? (<>
          {queueNames.map(name => {
            const queue = queues.filter(q => q.name === name)[0];
            return (<>
              <div className={styles.queue}>
                <div>
                  <h2>{name}</h2>
                  <span className={styles.queueLength}>Queue length:</span>&nbsp;{queue?.length}
                </div>
                <div className={`${styles.displayContainer} ${queue?.displayConnected ? styles.displayConnected : styles.displayDisconnected}`}>
                  <span className={styles.displayStatus}>{queue?.displayConnected ? "🟢 CONNECTED" : "🔴 DISCONNECTED"}</span>
                  {this.makeDisplay(queue.name, queue.displayState)}
                </div>
                <div>
                  {queue.controllerIds?.map((id, index) => 
                    <Controller 
                      controller={controllers.filter(c => c.id === id)[0]} 
                      inControl={index === 0}
                      snackbar={(msg) => this.setState({snackbar: msg})}
                      checked={checkedControllers.includes(id)}
                      onCheck={() => checkedControllers.includes(id) ? this.setState({checkedControllers: checkedControllers.filter(i => i !== id)}) : this.setState({checkedControllers: checkedControllers.concat([id])})}
                    />)}
                </div>
              </div>
            </>);
          })}
          <div className={styles.controllers}>
            <h2>All Controllers</h2>
            {controllers.map(controller => 
              <Controller 
                controller={controller}
                queue={queues.filter(queue => queue.controllerIds.includes(controller.id))[0]?.name}
                inControl={queues.map(queue => queue.controllerIds.indexOf(controller.id)).filter(i => i > -1)[0] === 0}
                snackbar={(msg) => this.setState({snackbar: msg})}
                checked={checkedControllers.includes(controller.id)}
                onCheck={() => checkedControllers.includes(controller.id) ? this.setState({checkedControllers: checkedControllers.filter(i => i !== controller.id)}) : this.setState({checkedControllers: checkedControllers.concat([controller.id])})}
              />
            )}
          </div>
          <div className={styles.controllers}>
            <h2>Banned IDs</h2>
            {bans?.length > 0 ? bans.map(ip => <>
              <div className={styles.ip} onClick={() => {
                WSConnection.sendMessage({removeBan: ip});
                this.setState({snackbar: "Unbanned ID"});
              }}>
                {ip}
              </div>
            </>) : (<p>(None)</p>)}
          </div>
          <Snackbar
            anchorOrigin={{vertical: "bottom", horizontal: "left"}}
            open={Boolean(snackbar)}
            autoHideDuration={3000}
            onClose={() => this.setState({snackbar: null})}
            message={snackbar}
          />
          <Dialog
            open={Boolean(error)}
            onClose={() => this.setState({error: null})}
          >
            <DialogTitle>ERROR</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {error}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({error: null})} color="primary">
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </>) : <CircularProgress/>}
      </div>
    </ThemeProvider>) : (<div style={{width: "100vw", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
      <h1 style={{fontSize: "80px"}}>👀</h1>
    </div>);
  }
}