import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { InputAdornment, TextField, Button, CircularProgress } from '@mui/material';
import { NewReleasesRounded, AddOutlined, SwapHorizRounded } from '@mui/icons-material';

import style from './style.module.scss';

const validate = values => {
  const errors = {};
  const requiredFields = [
    'number',
    'notes',
  ];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });
  if (!values.winFile && !values.macFile) {
    errors.winFile = 'You forgot to upload something!';
    errors.macFile = 'You forgot to upload something!';
  }
  if (values.number && values.number.replace(/\d+\.\d+\.\d+/g, "") !== "") {
    errors.number = 'The version number is in the incorrect format!';
  }
  return errors;
}

const renderTextField = ({
  label,
  input,
  placeholder,
  prefix,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
    className={custom.multiline ? style.textArea : style.textField}
    multiline={custom.multiline}
    rows={custom.multiline ? 8 : null}
    label={label}
    variant="outlined"
    placeholder={placeholder}
    error={touched && invalid}
    helperText={touched && error}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          {prefix}
        </InputAdornment>
      ),
    }}
    {...input}
    {...custom}
  />
);

const renderFileUpload = ({
  label,
  input,
  placeholder,
  meta: { touched, invalid, error },
  ...custom
}) => {
  const fileName = input.value && input.value[input.value.length - 1].name;
  return (
  <div className={style.fileUploadContainer}>
    <div>
      <p>
        {label + ": "}
      </p>
      <Button className={style.fileUpload} component="label" variant="contained">
        {fileName ? <SwapHorizRounded/> : <AddOutlined/>}
        {fileName || placeholder}
        <input
          type="file"
          className={`${touched && invalid ? style.error : null}`}
          onChange={(event) => {
            input.onChange(event);
          }}
          {...custom}
        />
      </Button>
    </div>
    <p className={style.error}>
      {touched && error} 
    </p>
  </div>
)};

const ReleaseForm = props => {
  const { handleSubmit, loading, windowsLatest, macLatest } = props;
  
  return (
    <form 
      className={style.form}
      onSubmit={handleSubmit}
    >
      <Field
        label="Version Number"
        name="number"
        component={renderTextField}
        prefix={<NewReleasesRounded/>}
        placeholder="e.g. 1.0.1"
      />
      <p className={style.latestVersion}>The latest version of Windows is: {windowsLatest || "Loading..."}</p>
      <p className={style.latestVersion}>The latest version of Mac is: {macLatest || "Loading..."}</p>
      <Field
        label="What's New Notes"
        name="notes"
        component={renderTextField}
        multiline={true}
        placeholder="What's New"
      />
      <Field
        label="Windows (exe installer)"
        name="winFile"
        component={renderFileUpload}
        placeholder="Add File..."
      />
      <Field
        label="Mac (pkg installer)"
        name="macFile"
        component={renderFileUpload}
        placeholder="Add File..."
      />

      <div className={style.submitContainer}>
        <button type="submit" className={`${style.btn} ${loading ? style.loading : ""}`} disabled={loading}>{loading ? <CircularProgress />: null}<p>Deploy Release</p></button>
      </div>
    </form>
  );
}

export default reduxForm({
  form: 'ReleaseForm',
  validate
})(ReleaseForm);