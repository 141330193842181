import React from 'react';
import {Typography, Tooltip} from '@mui/material';
import {LocalOfferRounded, CategoryRounded} from '@mui/icons-material';
import { mdiEgg, mdiFish, mdiBarley, mdiBreadSlice, mdiCow, mdiPeanut, mdiJellyfish, mdiSoySauce, mdiPalmTree, mdiNutrition } from '@mdi/js';
import Icon from '@mdi/react';

import styles from './style.module.scss';
import NoImage from './noImage.png';

const allergenIcons = {
  "Eggs": <Icon path={mdiEgg} size={1}/>,
  "Fish": <Icon path={mdiFish} size={1}/>,
  "Gluten": <Icon path={mdiBreadSlice} size={1}/>,
  "Milk": <Icon path={mdiCow} size={1}/>,
  "Peanuts": <Icon path={mdiPeanut} size={1}/>,
  "Shellfish": <Icon path={mdiJellyfish} size={1}/>,
  "Soy": <Icon path={mdiSoySauce} size={1}/>,
  "Tree Nuts": <Icon path={mdiPalmTree} size={1}/>,
  "Wheat": <Icon path={mdiBarley} size={1}/>,
};

function CopyrightImage(props) {
  const priceWhole = `${Math.floor(props.price)}`;
  const price = `${props.price}`;
  let priceCents = "0";
  if (price.includes(".")) {
    priceCents = price.split('.')[1];
  }
  return (
    <div className={`${styles.copyrightImage} ${props.className}`}>
      <img src={props.src || NoImage} alt="copyright image" lazyload/>
      {props.src ? <p className={styles.subtitle}>© The Cheesecake Factory</p> : null}
      {props.price > 0 ? <span className={styles.price}>
        <span className={styles.dollarSign}>$</span>
        <span className={styles.priceWhole}>{priceWhole}</span>
        <span className={styles.priceCents}>{priceCents}{priceCents.length === 1 ? "0" : ""}</span>
      </span> : null}
    </div>
  )
}

export default class CFList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disclaimerModalOpen: false,
    };
  }

  render() {
    const {items, filters, itemClicked, changeFilters} = this.props;
    const {category, hidePrices} = filters;
    return (<>
      <div className={styles.itemGrid}>
        {items.map(item => (
          <div className={`${styles.item} ${item.crossedOff ? styles.itemCrossed : ""}`}>
            <div className={item.crossedOff ? styles.itemHoverContainerCrossed : styles.itemHoverContainer} onClick={() => itemClicked(item)}>
              <CopyrightImage className={styles.itemImg} src={item.ImgUrl} price={hidePrices ? 0 : item.Price}/>
              <h1 className={styles.itemTitle}>{item.Name}</h1>
              <div className={styles.itemCalories}>
                {item.Calories > 0 ? <span className>{item.Calories} Calories</span> : null}
              </div>
              <p className={styles.itemDesc}>{item.Description}</p>
            </div>
            <div className={styles.itemCats}>
              <CategoryRounded/>
              <span
                className={styles.clickable}
                onClick={() => changeFilters({category: category.map(f => f.name === item.Category ? Object.assign(f, {checked: true}) : Object.assign(f, {checked: false}))})}
              >
                  {item.Category}
              </span>
            </div>
            {item.Tags.length !== 0 ? (
            <div className={styles.itemTags}>
              <LocalOfferRounded/>
              {item.Tags.map(tag => (<>
                <span 
                  className={styles.clickable} 
                  onClick={() => changeFilters({tag: filters.tag.map(f => f.name === tag ? Object.assign(f, {checked: true}) : Object.assign(f, {checked: false}))})}
                >
                  {tag}
                </span>
                {item.Tags.indexOf(tag) !== item.Tags.length - 1 ? <span>, </span> : null}
              </>))}
            </div>) : null}
            <div className={styles.itemAllergens}>
              <Icon path={mdiNutrition} size={1}/>
              {item.Allergens.length !== 0 ? (<>
              <span>Allergens:</span>
              {item.Allergens.map(allergen => 
                <span 
                  className={styles.clickable} 
                  onClick={() => changeFilters({allergen: filters.allergen.map(f => f.name === allergen ? Object.assign(f, {checked: false}) : f)})}
                >
                  <Tooltip title={`Contains ${allergen}`}>
                    {allergenIcons[allergen]}
                  </Tooltip>
                </span>)}</>) : (
                  <span 
                    className={styles.clickable} 
                    onClick={() => changeFilters({allergen: filters.allergen.map(f => f.name === "(none/unknown)" ? Object.assign(f, {checked: true}) : Object.assign(f, {checked: false}))})}
                  >
                    None/Unknown Allergens
                  </span>
                )}
            </div>
          </div>
        ))}
      </div>
    </>);
  }
}