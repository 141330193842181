import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { ChevronRight } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';

import {SetFavicon} from "utils";
import BackToHome from "components/BackToHome";
import { WSConnection } from "./websocket";
import { formatTime, queueStatus } from "./helpers";
import ErrorWrapper from './errorWrapper';
import WindowDraw from './assets/WindowDraw.svg';
import ChineseLantern from './assets/ChineseLantern.svg';
import DragonControl from './assets/DragonControl.svg';
import LampPost from './assets/LampPost.svg';
import SpiderControl from './assets/SpiderControl.svg';
import SkeletonTalking from './assets/SkeletonTalking.svg';
import styles from "./style.module.scss";

/* Interactions:
  - Draw/Type Text on Windows
    - Left Porch
    - Right Porch
    - Top Front
    - Bottom Front
    - Garage
  - Lantern Colors
  - Spiders Control
  - Skeleton Talking
*/
const WindowQueues = [
  "PorchWindowLeft",
  "PorchWindowRight",
  "FrontDoorWindowTop",
  "FrontDoorWindowBottom",
  "GarageWindow",
];
const SkeletonQueues = [
  "Skeleton1",
  "Skeleton2",
  "Skeleton3",
];

export default class Halloween2021 extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      queues: [],
    };
  }

  componentDidMount() {
    WSConnection.initialize();
    WSConnection.bindListener(this);

    WSConnection.sendMessage({
      action: "leaveQueue",
    });

    SetFavicon();
  }

  wsOpened = () => {
    WSConnection.sendMessage({
      action: "leaveQueue",
    });
  }

  messageReceived = (msg) => {
    if (msg && msg.length > 0) {
      this.setState({
        queues: msg,
      });
    }
  }

  errorThrown(err) {

  }

  render() {
    const {queues} = this.state;
    const windowQueues = queues?.filter(q => WindowQueues.includes(q.name));
    const skeletonQueues = queues?.filter(q => SkeletonQueues.includes(q.name));
    
    return (<ErrorWrapper>
      <Helmet title="Skousen's Halloween 2021 Experience"/>
      <BackToHome align="left" style={{marginLeft: "10px", marginTop: "10px"}}/>
      <div className={styles.home}>
        <h1>Skousen's Halloween 2021</h1>
        <h2 className={styles.homeH2}>It's your turn to control our setup this year!</h2>
        <div className={styles.buttonContainer}>
          <Link to="/halloween/windows">
          <button className={styles.button}>
            <img src={WindowDraw} alt="Window drawing" />
            <div className={styles.buttonMiddle}>
              <div className={styles.buttonTitle}>Draw on the Windows</div>
              {windowQueues?.length === 0 ? (<div className={styles.buttonStatus}>
                <CircularProgress className={styles.buttonQueueLoading}/>
                <span>Loading wait times...</span>
              </div>) : (<div className={styles.buttonStatus}>
                {windowQueues.reduce((v, q) => v + q.totalInLine, 0) === 0 ? 
                  <span className={styles.queueAvailable}>All Available</span> : 
                  windowQueues.reduce((v, q) => v + q.totalInLine, 0) < windowQueues.length ? 
                  <span className={styles.queueAvailable}>Some available</span> :
                  <span className={styles.queueWait}>{formatTime(windowQueues.reduce((v, q) => Math.min(v, q.waitTime), windowQueues[0].waitTime))} minimum wait</span>}
                &nbsp;&bull;&nbsp;<span className={styles.queueCount}>{windowQueues.reduce((v, q) => Math.min(v, q.totalInLine), windowQueues[0].totalInLine)} in shortest line</span>
              </div>)}
            </div>
            <ChevronRight className={styles.buttonArrow}/>
          </button>
          </Link>
          <Link to="/halloween/treeColors">
          <button className={styles.button}>
            <img src={ChineseLantern} alt="Chinese Lantern" />
            <div className={styles.buttonMiddle}>
              <div className={styles.buttonTitle}>Change the Tree Lantern Colors</div>
              {queueStatus(queues, "Lanterns")}
            </div>
            <ChevronRight className={styles.buttonArrow}/>
          </button>
          </Link>
          <Link to="/halloween/lampposts">
          <button className={styles.button}>
            <img src={LampPost} alt="Lamp Post" />
            <div className={styles.buttonMiddle}>
              <div className={styles.buttonTitle}>Change the Lamppost Colors</div>
              {queueStatus(queues, "Lampposts")}
            </div>
            <ChevronRight className={styles.buttonArrow}/>
          </button>
          </Link>
          <Link to="/halloween/spiders">
          <button className={styles.button}>
            <img src={SpiderControl} alt="Moving spider" />
            <div className={styles.buttonMiddle}>
              <div className={styles.buttonTitle}>Control the Spiders</div>
              {queueStatus(queues, "Spiders")}
            </div>
            <ChevronRight className={styles.buttonArrow}/>
          </button>
          </Link>
          <Link to="/halloween/skeletons">
          <button className={styles.button}>
            <img src={SkeletonTalking} alt="Skeleton" />
            <div className={styles.buttonMiddle}>
              <div className={styles.buttonTitle}>Talk through the Skeletons</div>
              {skeletonQueues?.length === 0 ? (<div className={styles.buttonStatus}>
                <CircularProgress className={styles.buttonQueueLoading}/>
                <span>Loading wait times...</span>
              </div>) : (<div className={styles.buttonStatus}>
                {skeletonQueues.reduce((v, q) => v + q.totalInLine, skeletonQueues[0].totalInLine) === 0 ? 
                  <span className={styles.queueAvailable}>All Available</span> : 
                  skeletonQueues.reduce((v, q) => v + q.totalInLine, skeletonQueues[0].totalInLine) < skeletonQueues.length ? 
                  <span className={styles.queueAvailable}>One available</span> :
                  <span className={styles.queueWait}>{formatTime(skeletonQueues.reduce((v, q) => Math.min(v, q.waitTime), skeletonQueues[0].waitTime))} minimum wait</span>}
                &nbsp;&bull;&nbsp;<span className={styles.queueCount}>{skeletonQueues.reduce((v, q) => Math.min(v, q.totalInLine), skeletonQueues[0].totalInLine)} in shortest line</span>
              </div>)}
            </div>
            <ChevronRight className={styles.buttonArrow}/>
          </button>
          </Link>
          {/*<Link to="/halloween/dragon">
          <button className={styles.button}>
            <img src={DragonControl} alt="Dragon" />
            <div className={styles.buttonMiddle}>
              <div className={styles.buttonTitle}>Control the Dragon</div>
              {queueStatus(queues, "Dragon")}
            </div>
            <ChevronRight className={styles.buttonArrow}/>
          </button>
          </Link>*/}
        </div>
      </div>
    </ErrorWrapper>);
  }
}