import React from 'react';
import { Button, IconButton, Dialog, DialogContent, DialogActions, Switch, FormControlLabel, TextField } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ReplayRounded, SettingsRounded } from '@mui/icons-material';
import { mdiClose, mdiRemote, mdiScoreboard, mdiTennis, mdiUndo } from '@mdi/js';
import { Icon } from '@mdi/react';

import Speechbox from "components/Speechbox";
import white from './white.png';
import styles from './style.module.scss';

const theme = createTheme({
  palette: {
    primary: {
      light: '#fff',
      main: '#fff',
      dark: '#fff',
      contrastText: '#000',
    },
    secondary: {
      light: '#5ABF3D',
      main: '#5ABF3D',
      dark: '#5ABF3D',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiDialogTitle: {
      text: {
        color: "black",
      },
    },
  },
});

export default class Picklescore extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      teams: [
        {
          score: 0,
          server: 2,
          serverNameIndex: 0,
          serverNames: ["", ""],
          color: "#e3524f"
        },
        {
          score: 0,
          server: 1,
          serverNameIndex: 0,
          serverNames: ["", ""],
          color: "#4faef7"
        }
      ],
      currentTeam: 0,
      settings: {
        displayServerNames: false,
        missKey: "SPACE",
        scoreKey: "ENTER",
        undoKey: "BACKSPACE",
        touchscreenMode: 'ontouchstart' in document.documentElement,
        remoteMode: false,
      },
      undoTree: [],
      modeOverlayOpen: false,
      settingsModalOpen: false,
      controllerSettingsAnchorEl: null,
    };

    window.onkeyup = (ev) => {
      let key = ev.key.toUpperCase();
      if (key === " ") {
        key = "SPACE";
      }
      const {settings, settingsModalOpen, assigningKey} = this.state;
      if (settingsModalOpen) {
        if (assigningKey) {
          switch (ev.key.toUpperCase()) {
            case settings.missKey:
              settings.missKey = "UNBOUND";
              break;
            case settings.scoreKey:
              settings.scoreKey = "UNBOUND";
              break;
            case settings.undoKey:
              settings.undoKey = "UNBOUND";
              break;
            default:
              break;
          }
          
          settings[assigningKey] = key;
          return this.setState({
            assigningKey: null,
          });
        }
      }
      else {
        switch (key) {
          case settings.missKey:
            this.missed();
            break;
          case settings.scoreKey:
            this.scored();
            break;
          case settings.undoKey:
            this.undo();
            break;
          default:
            break;
        }
      }
    };
  }

  componentDidMount() {
    console.log(`"${localStorage.getItem("settings")}"`)
    let settings = this.state.settings;
    if (localStorage.getItem("settings")) {
      settings = JSON.parse(localStorage.getItem("settings"));
    }
    if (localStorage.getItem("serverNames")) {
      
    }
    this.setState({settings});
    this.saveSettings();
  }

  saveSettings = () => {
    console.log(`saving settings...`);
    localStorage.setItem("settings", JSON.stringify(this.state.settings));
  }

  missed = () => {
    this.saveStateToUndoTree();
    let {teams, currentTeam} = this.state;
    let team = teams[currentTeam];
    team.server++;
    team.serverNameIndex = (team.serverNameIndex + 1) % 2;
    if (team.server > 2) {
      team.server = 1;
      currentTeam++;
      if (currentTeam > 1) currentTeam = 0;
      teams[currentTeam].serverNameIndex = 0;
    }    
    this.setState({
      teams,
      currentTeam,
    });
  }

  scored = () => {
    this.saveStateToUndoTree();
    let {teams, currentTeam} = this.state;
    //increment score and swap servers
    let team = teams[currentTeam];
    team.score++;
    team.serverNames = [
      team.serverNames[1],
      team.serverNames[0],
    ];
    team.serverNameIndex = (team.serverNameIndex + 1) % 2;
    this.setState({
      teams,
    });
  }

  saveStateToUndoTree = () => {
    const {teams, currentTeam, undoTree} = this.state;
    undoTree.push({
      teams: [
        {score: teams[0].score, server: teams[0].server, serverNameIndex: teams[0].serverNameIndex, serverNames: teams[0].serverNames.slice()},
        {score: teams[1].score, server: teams[1].server, serverNameIndex: teams[1].serverNameIndex, serverNames: teams[1].serverNames.slice()},
      ],
      currentTeam,
    });
    this.setState({
      undoTree,
    });
  }
  
  undo = () => {
    const {teams, undoTree} = this.state;
    const lastItem = undoTree.pop();
    if (lastItem?.teams) {
      const st = {
        undoTree,
        currentTeam: lastItem.currentTeam,
        teams: [
          Object.assign(teams[0], lastItem.teams[0]),
          Object.assign(teams[1], lastItem.teams[1]),
        ],
      };
      console.log(st);
      this.setState(st);
    }
  }

  reset = () => {
    const {teams} = this.state;
    this.setState({
      teams: [
        Object.assign(teams[0], {score: 0, server: 2, serverNameIndex: 0,}),
        Object.assign(teams[1], {score: 0, server: 1, serverNameIndex: 0,}),
      ],
      currentTeam: 0,
      undoTree: [],
    });
  }

  render() {
    const {teams, currentTeam: currentTeamNum, settings, modeOverlayOpen, settingsModalOpen, controllerSettingsAnchorEl} = this.state;
    const currentTeam = teams[currentTeamNum];
    const oppositeTeam = teams[(currentTeamNum + 1) % 2];
    const {displayServerNames, touchscreenMode, remoteMode, missKey, scoreKey, undoKey} = settings;

    const ClickableKey = (label, varName) => (
      <div style={{marginTop: "10px"}}>
        <span className={styles.optionName}>{label}:</span>
        <button className={`${styles.key} ${styles.clickable}`} onClick={(ev) => {
          if (ev.clientX > 0 && ev.clientY > 0) { //if statement prevents against re-clicking button when pressing space or enter
            this.setState({
              assigningKey: varName
            });
          }
        }}>{this.state.assigningKey === varName ? "WAITING..." : settings[varName]}</button>
      </div>    
    );
    return (
      <ThemeProvider theme={theme}>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              <IconButton className={styles.settingsButton} color="primary" style={{fontSize: "35px"}} onClick={this.reset}>
                <ReplayRounded fontSize="inherit"/>
              </IconButton>
              <IconButton className={styles.settingsButton} color="primary" onClick={(e) => this.setState({controllerSettingsAnchorEl: e.target})}>
                <Icon path={mdiRemote} size={1}/>
              </IconButton>
              <Speechbox 
                open={Boolean(controllerSettingsAnchorEl)} 
                anchorEl={controllerSettingsAnchorEl}
                onClose={() => this.setState({controllerSettingsAnchorEl: null})}
              >
                <h1 style={{color: "black"}}>Hi mom!</h1>
                <p>This is a test, this is some lorem ipsum, this is a speechbox. This is a test, this is some lorem ipsum, this is a speechbox. This is a test, this is some lorem ipsum, this is a speechbox. This is a test, this is some lorem ipsum, this is a speechbox.</p>
              </Speechbox>
            </div>
            <div className={styles.headerCenter}>
              <img src={white} alt="pickleball ball"/>
              <h1>Picklescore</h1>
            </div>
            <div className={styles.headerRight}>
              <IconButton className={styles.settingsButton} color="primary" onClick={() => this.setState({settingsModalOpen: true, assigningKey: null})} style={{fontSize: "35px"}}>
                <SettingsRounded fontSize="inherit"/>
              </IconButton>
              <Dialog
                open={settingsModalOpen}
                onClose={() => {this.setState({settingsModalOpen: false}); this.saveSettings();}}
              >
                <h2 style={{color: "black", margin: "24px", marginBottom: "10px"}}>Settings</h2>
                <DialogContent className={styles.settingsModal}>
                  <FormControlLabel 
                    label="Show server names" 
                    control={
                      <Switch 
                        checked={displayServerNames} 
                        onChange={() => this.setState({settings: {...settings, displayServerNames: !displayServerNames}})}
                      />
                    }
                  />
                  {displayServerNames && <div style={{marginBottom: "20px"}}>
                    <div style={{display: "flex", marginBottom: "10px"}}>
                      <TextField label="Team 1, Server 1" color="secondary" variant="outlined"
                        value={teams[0].serverNames[0]}
                        onChange={(e) => this.setState({teams: [
                          {...teams[0], serverNames: [e.target.value, teams[0].serverNames[1]]},
                          teams[1],
                        ]})}
                      />
                      <TextField label="Team 1, Server 2" color="secondary" variant="outlined"
                        value={teams[0].serverNames[1]}
                        onChange={(e) => this.setState({teams: [
                          {...teams[0], serverNames: [teams[0].serverNames[0], e.target.value]},
                          teams[1],
                        ]})}
                      />
                    </div>
                    <div style={{display: "flex"}}>
                      <TextField label="Team 2, Server 1" color="secondary" variant="outlined"
                        value={teams[1].serverNames[0]}
                        onChange={(e) => this.setState({teams: [
                          teams[0],
                          {...teams[1], serverNames: [e.target.value, teams[1].serverNames[1]]},
                        ]})}
                      />
                      <TextField label="Team 2, Server 2" color="secondary" variant="outlined"
                        value={teams[1].serverNames[1]}
                        onChange={(e) => this.setState({teams: [
                          teams[0],
                          {...teams[1], serverNames: [teams[1].serverNames[0], e.target.value]},
                        ]})}
                      />
                    </div>
                  </div>}
                  <div style={{marginTop: "10px"}}>
                    <span className={styles.optionName}>Scoring Mode: </span>
                    <Button variant={touchscreenMode ? "contained" : "outlined"} color="secondary" onClick={() => this.setState({
                      settings: {
                        ...this.state.settings,
                        touchscreenMode: true,
                      },
                    })}>Touch</Button>
                    <Button variant={touchscreenMode ? "outlined" : "contained"} color="secondary" onClick={() => this.setState({
                      settings: {
                        ...this.state.settings,
                        touchscreenMode: false,
                      },
                    })}>Keyboard</Button>
                  </div>
                  {!touchscreenMode && <>
                    {ClickableKey("Miss Key", "missKey")}
                    {ClickableKey("Score Key", "scoreKey")}
                    {ClickableKey("Undo Key", "undoKey")}
                  </>}
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => this.setState({settingsModalOpen: false})} color="secondary" autoFocus>
                    Done
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
          {!remoteMode ? (<>
            <div className={styles.body}>
              <div className={styles.half} style={{backgroundColor: currentTeam.color}}>
                {currentTeam.score}
              </div>
              <div className={styles.half} style={{backgroundColor: oppositeTeam.color}}>
                {oppositeTeam.score}
              </div>
              <div className={styles.server}>
                  {currentTeam.server}
                {!displayServerNames && this.state.undoTree?.length === 0 && 
                  <Button variant="contained" onClick={() => this.setState({settingsModalOpen: true, settings: {...settings, displayServerNames: true}})}>
                    Show names?
                  </Button>}
                {displayServerNames && 
                  <span className={styles.serverName} style={{color: currentTeam.color}}>
                    {currentTeam.serverNames[currentTeam.serverNameIndex]}
                  </span>}
              </div>
            </div>
            <div className={styles.footer}>
              {touchscreenMode ? <>
                <Button variant="contained" onClick={this.missed}>MISS</Button>
                <Button variant="contained" onClick={this.scored}>SCORE</Button>
                <Button variant="contained" onClick={this.undo}>UNDO</Button>
              </> : <>
                Press <span className={styles.key}>{missKey}</span> for miss, <span className={styles.key}>{scoreKey}</span> for score, <span className={styles.key}>{undoKey}</span> to undo
              </>}
            </div>
          </>) : (<>
            <div className={styles.remoteBody}>
              <div className={styles.topRow}>
                <button className={`${styles.remoteButton} ${styles.red}`} onClick={() => {}}>
                  <Icon path={mdiClose} size={1}/>
                  Miss
                </button>
                <button className={styles.remoteButton} onClick={() => {}}>
                  <Icon path={mdiTennis} size={1}/>
                  Score
                </button>
              </div>
              <div className={styles.bottomRow}>
                <button className={`${styles.remoteButton} ${styles.blue}`} onClick={() => {}}>
                  <Icon path={mdiUndo} size={1}/>
                  Undo
                </button>
              </div>
            </div>
          </>)}
          <div className={`${styles.overlay} ${!modeOverlayOpen ? styles.overlayOut : null}`}>
            <h1>Choose your Mode:</h1>
            <div className={styles.modeButtons}>
              <button className={styles.modeButton} onClick={() => this.setState({modeOverlayOpen: false, settings: {...settings, remoteMode: false}})}>
                <span className={styles.title}>SCOREBOARD</span>
                <Icon path={mdiScoreboard} size={1} className={styles.modeIcon}/>
                <span>Use this device to display the score. Control it using this device or remotely by pairing a phone or tablet.</span>
              </button>
              <button className={styles.modeButton} onClick={() => this.setState({modeOverlayOpen: false, settings: {...settings, remoteMode: true}})}>
                <span className={styles.title}>REMOTE</span>
                <Icon path={mdiRemote} size={1} className={styles.modeIcon}/>
                <span>Control the scoreboard using this device.</span>
              </button>
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}