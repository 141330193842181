import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { InputAdornment, TextField } from '@mui/material';
import { PersonRounded, MailOutlineRounded } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';

import RecaptchaItem from 'components/RecaptchaItem';
import style from './style.module.scss';

const validate = values => {
  const errors = {};
  const requiredFields = [
    'name',
    'email',
    'message',
    'recaptcha',
  ];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });
  if (values.email &&
    !/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:+)\])/.test(values.email)
  ) {
    errors.email = 'Invalid email address';
  }
  return errors;
}

const renderTextField = ({
  label,
  input,
  placeholder,
  prefix,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
    className={custom.multiline ? style.textArea : style.textField}
    multiline={custom.multiline}
    rows={custom.multiline ? 8 : null}
    label={label}
    variant="outlined"
    placeholder={placeholder}
    error={touched && invalid}
    helperText={touched && error}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          {prefix}
        </InputAdornment>
      ),
    }}
    {...input}
    {...custom}
  />
);

const renderRecaptcha = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <>
    <RecaptchaItem
      onChange={input.onChange}
    />
    {touched ? <p className={style.recaptchaHelp}>{error}</p> : null}
  </>
);

const ContactForm = props => {
  const { handleSubmit, loading } = props;

  return (
    <form 
      className={style.form}
      onSubmit={handleSubmit}
    >
      <Field
        label="Your Name"
        name="name"
        component={renderTextField}
        prefix={<PersonRounded/>}
        placeholder="Ben Wyatt"
      />
      <Field
        label="Your Email"
        name="email"
        component={renderTextField}
        prefix={<MailOutlineRounded/>}
        placeholder="benwyatt@pawneecity.org"
      />
      <Field
        label="Your Message"
        name="message"
        multiline
        component={renderTextField}
        placeholder="Feedback, questions, comments, anything!"
      />

      <Field
        name="recaptcha"
        component={renderRecaptcha}
      />

      <div className={style.sendContainer}>
        <button type="submit" className={`${style.send} ${loading ? style.loading : ""}`} disabled={loading}>{loading ? <CircularProgress />: null}<p>Send</p></button>
      </div>
    </form>
  );
}

export default reduxForm({
  form: 'ContactForm',
  validate
})(ContactForm);