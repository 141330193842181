import React from 'react';
import { Link } from 'react-router-dom';
import { TextField } from '@mui/material';
import {Helmet} from "react-helmet";

import manifest from './manifest';

import ItemCard from 'components/ItemCard';
import BackToHome from 'components/BackToHome';
import styles from './style.module.scss';

export default class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: ""
    };
  }
  
  GenerateList(filteredManifest) {
    let items = [];
    for (var i = 0; i < filteredManifest.length; i++) {
      var proj = filteredManifest[i];
      var master = require.context('./', true);
      const favicon = proj.favicon.startsWith("https://") ? proj.favicon : master(`./${proj.folder}${proj.favicon}`);
      const inner = <ItemCard headStyle={{'background-color': proj.bg_color}} className={styles.projectCard} title={
        <>
          <img
            src={favicon}
            alt='Favicon'
            className={styles.projectFavicon}
          />
          <span class={styles.projectTitle} style={{color: proj.color}}>
            {proj.name}
          </span>
        </>
        }>
        {proj.description}
        <span className={styles.projectDate}>
          {proj.date}
        </span>
      </ItemCard>;
      items.push(proj.url.startsWith("https://") ? 
        <a href={proj.url}>{inner}</a> :
        <Link to={`/projects/${proj.url}`}>{inner}</Link>
      );
    }
    return items;
  }

  Search(event) {
    this.setState({search: event.target.value.toLowerCase()});
  }

  FilterList() {
    const {search} = this.state;
    return manifest.filter(post => post.name.toLowerCase().includes(search) || post.description.toLowerCase().includes(search) || post.url.toLowerCase().includes(search) || post.date.includes(search));
  } 

  render() {
    const list = this.GenerateList(this.FilterList());
    return (
      <div className="centeredContainerHorz">
        <Helmet title="Projects"/>
        <div className={styles.container}>
          <BackToHome align="left"/>
          <h1 className={styles.title}>Projects</h1>
          <TextField label="Search for a project" className={styles.search} variant="outlined" onChange={(event) => this.Search(event)}/>
          <div className={styles.list}>
            {list}
            {list.length === 0 ? (
                <div className={styles.notFound}>
                  <h1>
                    <span role="img" aria-label="left-pointing magnifying glass">🔍</span> No results.
                  </h1>
                </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}