import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet';

import Halloween2021 from './pages/halloween2021';
import HalloweenPageHandler from './pages/halloween2021/pageHandler';
import Projects from './pages/projects';
import ProjectLoader from './pages/projects/loader';
import Home from './pages/home';
import About from './pages/about';
import Blog from './pages/blog';
import BlogLoader from './pages/blog/loader';
import Contact from './pages/contact';
import NotFound from './pages/NotFound';
import DanceCuts from './pages/dancecuts';

import './styles/master.scss';
import './index.scss';

const theme = createTheme({
  palette: {
    primary: {
      light: '#fff',
      main: '#fff',
      dark: '#fff',
      contrastText: '#000',
    },
    secondary: {
      light: '#000',
      main: '#000',
      dark: '#000',
      contrastText: '#fff',
    },
  },
});

const rootReducer = combineReducers({
  // ...your other reducers here
  // you have to pass formReducer under 'form' key,
  // for custom keys look up the docs for 'getFormState'
  form: formReducer
});

const store = createStore(rootReducer);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Helmet titleTemplate="%s - Jo the Dev" defaultTitle="Jo the Dev"/>
          <Router>
            <Switch>
              <Route path="/projects/:id/:subPage">
                <ProjectLoader />
              </Route>
              <Route path="/projects/:id">
                <ProjectLoader />
              </Route>
              <Route path="/projects">
                <Projects />
              </Route>
              <Route path="/blog/:id">
                <BlogLoader />
              </Route>
              <Route path="/blog">
                <Blog />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
              <Route exact path="/">
                <Home />
              </Route>
              <Route>
                <NotFound />
              </Route>
            </Switch>
          </Router>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//import * as serviceWorker from './serviceWorker';
// serviceWorker.unregister();
