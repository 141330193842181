import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import './styles/ColorWheel.css';
import { coordinatesToHS, hsToCoordinates } from './helpers/utils';
import LevelBar from './LevelBar';
import TreeLanternMap from "../assets/TreeLanternMap.svg";

const ColorWheel = ({
  color,
  size,
  setColor,
  treeColor,
}) => {
  const wheel = useRef(null);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    const mouseDown = event => {
      if (wheel.current.contains(event.target)) {
        setEditing(true);
      }
    };
    const mouseUp = () => {
      setEditing(false);
    };
    const mouseMove = event => {
      if (editing) {
        if (event.touches?.length > 0) {
          return setColor(
            coordinatesToHS(
              (event.touches[0].clientX - wheel.current.getBoundingClientRect().x) / size,
              (event.touches[0].clientY - wheel.current.getBoundingClientRect().y) / size,
            ),
          );
        }
        setColor(
          coordinatesToHS(
            (event.clientX - wheel.current.getBoundingClientRect().x) / size,
            (event.clientY - wheel.current.getBoundingClientRect().y) / size,
          ),
        );
      }
    };

    window.addEventListener('mousemove', mouseMove);
    window.addEventListener('mousedown', mouseDown);
    window.addEventListener('mouseup', mouseUp);
    window.addEventListener('touchmove', mouseMove);
    window.addEventListener('touchstart', mouseDown);
    window.addEventListener('touchend', mouseUp);

    return () => {
      window.removeEventListener('mousedown', mouseDown);
      window.removeEventListener('mouseup', mouseUp);
      window.removeEventListener('mousemove', mouseMove);
      window.removeEventListener('touchmove', mouseMove);
      window.removeEventListener('touchstart', mouseDown);
      window.removeEventListener('touchend', mouseUp);
    };
  }, [editing, setColor, size]);

  const { x, y } = hsToCoordinates(color.h, color.s);

  const onMouseDown = useCallback(event => {
    setColor(
      coordinatesToHS(
        (event.clientX - event.currentTarget.getBoundingClientRect().x) / size,
        (event.clientY - event.currentTarget.getBoundingClientRect().y) / size,
      ),
    );
  }, [setColor, size]);

  return (
    <div className="colorWheel">
      <div
        ref={wheel}
        className={treeColor ? "wheelEmpty" : "wheel"}
        onMouseDown={onMouseDown}
        onTouchMove={onMouseDown}
        onTouchStart={onMouseDown}
        role="button"
        tabIndex={-5}
      >
        <div
          className="handle"
          style={{
            top: y * size,
            left: x * size,
            width: size / 15,
            height: size / 15,
            border: `${size / 150}px solid black`,
          }}
        />
        {treeColor ? <img src={TreeLanternMap} width={size} height={size}/> : null}
      </div>
    </div>
  );
};

ColorWheel.propTypes = {
  /** Current picked color */
  color: PropTypes.shape({
    h: PropTypes.number,
    s: PropTypes.number,
    l: PropTypes.number,
  }),
  /** Size of color wheel */
  size: PropTypes.number.isRequired,
  /** Callback function to set color */
  setColor: PropTypes.func.isRequired,
};

ColorWheel.defaultProps = {
  color: {
    h: 0,
    s: 100,
    l: 50,
  },
};
export default ColorWheel;
