import React from "react";
import { IconButton, Tooltip, Snackbar } from '@mui/material';
import MuiAlert from '@mui/lab/Alert';
import { ArrowBackRounded, EmailRounded } from '@mui/icons-material';
import useWindowSize from 'react-use/lib/useWindowSize'

import { YouTube } from 'components/Icons';
import credits from "../data/credits.json";
import styles from "./style.module.scss";

export default function SVBCredits(props) {
  return (<div>
    <div className={styles.GameApp} >
      <div className={styles.container}>
        <Tooltip title="Back">
          <IconButton className={styles.btn_back} onClick={() => window.location.assign("/projects/street-view-bingo")}>
            <ArrowBackRounded />
          </IconButton>
        </Tooltip>
        <h1 className={styles.creditsH1}>CREDITS</h1>
        <hr className={styles.creditsHR}/>
        <h2 className={styles.creditsH2}>Created by Jordan Skousen</h2>
        <h2 className={styles.creditsH3}>Inspired by <a href="https://www.youtube.com/c/GeoWizard" target="_blank" rel="noopener noreferrer">Tom Davies</a></h2>
        <hr className={styles.creditsHR}/>
        <h3 className={styles.creditsH3}>Here's a list of people I may or may not have stolen bingo ideas from:</h3>
        {credits.map(credit => 
          <h4 className={styles.creditsH4}>
            {credit.source === "YouTube" ? 
            (<a href={credit.link} target="_blank" rel="noopener noreferrer"><div className={styles.yt_icon}><YouTube/></div>{credit.name}</a>) : 
            credit.source === "Email" ? 
            (<div><EmailRounded/>{credit.name}</div>) : null} 
          </h4>
        )}
      </div>
    </div>
  </div>);
}