import React from 'react';
import {Button, Typography} from '@mui/material';
import { LoopRounded, PrivacyTipOutlined } from '@mui/icons-material';
import {createTheme, ThemeProvider} from '@mui/material/styles';

import Header from "./header";
import List from "./list";
import Modal from 'components/Modal';
//import data from './data.json';
import styles from './style.module.scss';
import categories from '../ChameleonExpansion/categories';

const theme = createTheme({
  palette: {
    primary: {
      light: '#950055',
      main: '#950055',
      dark: '#950055',
      contrastText: '#fff',
    },
    secondary: {
      light: '#950055',
      main: '#950055',
      dark: '#950055',
      contrastText: '#000',
    },
  },
});

export default class CheesecakeFactory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      filteredItems: [],
      filters: {
        category: [],
        tag: [{name: "(no tags)", checked: true}],
        allergen: [{name: "(none/unknown)", checked: true}],
        imageOnly: false,
        hidePrices: false,
      },
      disclaimerModalOpen: true,
      wipModalOpen: false,
      crossedItems: [],
    };
  }

  async componentDidMount() {
    try { 
      const json = await (await fetch("https://jothedev-cors-anywhere.herokuapp.com/https://nomnom-prod-api.thecheesecakefactory.com/restaurants/171338/menu?nomnom=add-restaurant-to-menu,nested-menu&includedisabled=true", {
        method: "GET",
      })).json();
      console.log(json);

      if (json?.categories?.length > 0) {
        const data = json.categories.map(category => category.subCategories.map(subcat => subcat.products.map(prod => ({
          ImgUrl: prod.imagefilename && `https://olo-images-live.imgix.net/${prod.imagefilename}`,
          Name: prod.name,
          Description: prod.description,
          Category: category.name,
          Tags: [],
          Allergens: [],
          Calories: parseInt(prod.basecalories || "0") || 0,
          Price: prod.cost,
        })))).flat(2);
        let transData = data.map(i => Object.assign(i, {
          crossedOff: false,
          Tags: i.Tags.map(tag => tag.split(" ").map(i => i[0].toUpperCase() + i.substr(1, i.length - 1)).join(" ")),
        }));
    
        let categoryFilters = [], categoryFiltersS = [];
        transData.forEach(item => {
          if (categoryFiltersS.indexOf(item.Category) === -1) {
            categoryFiltersS.push(item.Category);
            categoryFilters.push({
              name: item.Category,
              checked: true,
            });
          }
        });
        let tagFiltersS = [];
        transData.forEach(item => {
          item.Tags.forEach(tag => {
            if (tagFiltersS.indexOf(tag) === -1) {
              tagFiltersS.push(tag);
            }
          });
        });
        tagFiltersS.sort();
        let allergenFiltersS = [];
        transData.forEach(item => {
          item.Allergens.forEach(allergen => {
            if (allergenFiltersS.indexOf(allergen) === -1) {
              allergenFiltersS.push(allergen);
            }
          });
        });
        allergenFiltersS.sort();

        this.setState({
          items: transData,
          filteredItems: transData,
          filters: {
            category: categoryFilters,
            tag: [{name: "(no tags)", checked: true}].concat(tagFiltersS.map(f => {return {
              name: f,
              checked: true,
            }})),
            allergen: [{name: "(none/unknown)", checked: true}].concat(allergenFiltersS.map(a => {return {
              name: a,
              checked: true,
            }})),
            imageOnly: false,
            hidePrices: false,
          },
        });
      }
    }
    catch (err) {
      console.log(`ERROR failed to get menu data: `, err);
    }
  }

  applyFilters = (item) => {
    const {category, tag, allergen, imageOnly} = this.state.filters;
    const tagStrings = tag.filter(f => f.checked).map(f => f.name);
    // CATEGORIES
    return (category.filter(f => f.checked).map(f => f.name).includes(item.Category)) &&
    // TAGS
      ((item.Tags.length === 0 && tagStrings.includes("(no tags)")) ||
      tagStrings.filter(tag => item.Tags.includes(tag)).length > 0) &&
    // ALLERGENS
      ((item.Allergens.length === 0 && allergen.filter(f => f.checked).map(f => f.name).includes("(none/unknown)")) ||
      item.Allergens.length > 0 && allergen.filter(a => item.Allergens.includes(a.name) && !a.checked).length === 0) &&
    // IMAGE ONLY
      (!imageOnly || item.ImgUrl != null);
  }

  changeFilters = (newFilter) => {
    const {items} = this.state;
    this.setState({
      filters: Object.assign(this.state.filters, newFilter),
      filteredItems: items.filter(this.applyFilters),
    });
  }

  itemClicked = (item) => {
    let {items, crossedItems} = this.state;
    if (items.includes(item)) {
      crossedItems.push(Object.assign(item, {crossedOff: true}));
      items.splice(items.indexOf(item), 1);
    }
    else {
      items.push(item);
      crossedItems.splice(crossedItems.indexOf(item), 1);
    }
    this.setState({
      items,
      crossedItems,
    });
  }
  
  render() {
    const {filters, filteredItems, crossedItems, disclaimerModalOpen, wipModalOpen} = this.state;
    const header = <Header
      changeFilters={this.changeFilters}
      resetFilters={() => this.setState({
        filters: {
          category: this.state.filters.category.map(f => Object.assign(f, {checked: true})),
          tag: this.state.filters.tag.map(f => Object.assign(f, {checked: true})),
          allergen: this.state.filters.allergen.map(f => Object.assign(f, {checked: true})),
          imageOnly: false,
          hidePrices: false,
        },
      })}
      filters={filters}
    />;
    return (<>
      <ThemeProvider theme={theme}>
        <div className={styles.container}>
          <Modal 
            visible={disclaimerModalOpen}
            title="DISCLAIMER"
            buttons={[
              {
                text: "OK, GOT IT",
                primary: true,
                onClick: () => this.setState({disclaimerModalOpen: false})
              }
            ]}
            onClose={() => this.setState({disclaimerModalOpen: false})}
          >
            <p>This site is not affiliated with or endorsed by The Cheesecake Factory ("TCF Co. LLC"). Images, descriptions, prices, and all other information is sourced from their website, <a href="https://thecheesecakefactory.com" target="_blank" rel="noopener noreferrer">thecheesecakefactory.com</a>.</p>
            <p>Not all items may be available at your local Cheesecake Factory, and prices may vary from location to location. To check prices and availability, go to <a href="https://order.thecheesecakefactory.com/" target="_blank" rel="noopener noreferrer">order.thecheesecakefactory.com</a>.</p>
            <p>This site provides allergen and nutritional information which has been sourced from <a href="https://thecheesecakefactory.com" target="_blank" rel="noopener noreferrer">The Cheesecake Factory's website.</a> This information is not kept up-to-date 
            and is not 100% accurate. If you have serious nutritional needs, do not rely solely on this website for nutritional information. Ask your local Cheesecake Factory location for nutritional guides, or see their
            official online <a href="https://www.thecheesecakefactory.com/allergy" target="_blank" rel="noopener noreferrer">allergen guide</a> and <a href="https://www.thecheesecakefactory.com/assets/pdf/Nutritional_Guide.pdf" target="_blank" rel="noopener noreferrer">nutrition guide</a> to get the most accurate information.</p>
            <p>(TL/DR: this is all for fun, don't take it seriously!)</p>
          </Modal>
          <Modal 
            visible={wipModalOpen}
            title="WARNING: WIP"
            buttons={[
              {
                text: "TAKE ME BACK",
                primary: true,
                onClick: () => window.location.assign("/"),
              },
              {
                text: "PROCEED",
                primary: true,
                onClick: () => this.setState({wipModalOpen: false})
              }
            ]}
            onClose={() => this.setState({wipModalOpen: false})}
          >
            <p>This project is a work-in-progress. It runs SUPER slowly and needs optimization. I also haven't added all the features I want yet :)</p>
            <p>If you don't want to deal with the lagginess, you can head back to the homepage using the button below.</p>
          </Modal>
          <div className={styles.body}>
            {header}
            <div className={styles.displayCountContainer}>
              <Typography variant="button" className={styles.displayCount}>Displaying <span className={styles.green}>{filteredItems.length}</span> item{filteredItems.length != 1 ? "s" : ""}</Typography>
            </div>
            <List
              changeFilters={this.changeFilters}
              filters={filters}
              items={filteredItems}
              itemClicked={this.itemClicked}
            />
            {crossedItems.length > 0 ? (<>
              <span className={styles.crossedTitle}>
                Crossed-Off Items:
                <Button
                  className={styles.resetButton}
                  variant="contained"
                  color="primary"
                  startIcon={<LoopRounded/>}
                  onClick={() => {
                    this.setState({
                      items: this.state.items.map(i => {i.crossedOff = false; return i;}),
                    });
                  }}
                >
                  Reset
                </Button>
              </span>
              <List
                changeFilters={this.changeFilters}
                filters={filters}
                items={crossedItems}
                itemClicked={this.itemClicked}
              />
            </>) : null}
          </div>
          <div className={styles.stickyHeader}>
            {header}
          </div>
        </div>
      </ThemeProvider>
    </>);
  }
}