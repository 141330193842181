import React from "react"
import { Link } from 'react-router-dom';
import { TextField } from '@mui/material';
import { Helmet } from 'react-helmet';

import {SetFavicon} from 'utils';
import ItemCard from 'components/ItemCard';
import BackToHome from 'components/BackToHome';
import manifest from './manifest';

import styles from './style.module.scss'

class Blog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: ""
    };

    this.FilterList = this.FilterList.bind(this);
    this.Search = this.Search.bind(this);

    SetFavicon();
  }

  Search(event) {
    this.setState({search: event.target.value.toLowerCase()});
  }

  FilterList() {
    const { search } = this.state;
    return manifest.filter(post => post.title.toLowerCase().includes(search) || post.snippet.toLowerCase().includes(search) || post.date.includes(search));
  }

  GenerateList(filteredManifest) {
    let items = [];
    for (var i = 0; i < filteredManifest.length; i++) {
      var post = filteredManifest[i];
      var master = require.context('./', true);
      const image = master(`./images/${post.image}`);
      items.push(
        <Link to={`/blog/${post.file}`}>
          <ItemCard className={styles.postCard} bodyStyle={{"background-color": post.background, "color": post.color}} title={
            <>
              <span class={styles.postTitle}>
                {post.title}
              </span>
            </>
            }>
            <img src={image} alt={post.imageAlt} className={styles.postImage} />
            {post.snippet}
            <span className={styles.postDate} style={{color: post.color}}>
              {post.date}
            </span>
          </ItemCard>
        </Link>
      );
    }
    return items;
  }

  render() {
    const list = this.GenerateList(this.FilterList());
    return (
      <div className="centeredContainerHorz">
        <Helmet title="Blog"/>
        <div className={styles.container}>
          <BackToHome align="left"/>
          <h1 className={styles.title}>Blog Posts</h1>
          <TextField label="Search for a post" variant="outlined" className={styles.search} onChange={(event) => this.Search(event)} />
          <div className={styles.list}>
            <div className={styles.widthHolder}></div>
            {list.length > 0 ? list : (
              <div className={styles.notFound}>
                <h1>
                  <span role="img" aria-label="left-pointing magnifying glass">🔍</span> No results.
                </h1>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default Blog;