import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { TextField, Slider, Checkbox, FormControlLabel } from '@mui/material';

import style from './style.module.scss';

const validate = values => {
  const errors = {};
  const requiredFields = [
    'items',
    'time',
  ];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });
  return errors;
}

const renderTextField = ({
  label,
  input,
  placeholder,
  type,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
    label={label}
    variant="outlined"
    className={`${style.formTextField} ${custom.multiline ? style.formTextArea : null}`}
    rows={custom.multiline ? 8 : null}
    placeholder={placeholder}
    type={type}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
);

const renderSlider = ({
  label,
  input,
  showValueText,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <div className={style.formItem}>
    <p className={style.formLabel}>{label}: </p>
    <Slider
      className={style.formSlider}
      onChange={(event, value) => input.onChange(value)}
      value={input.value}
      valueLabelDisplay="auto"
      {...custom}
    />
    <span className={style.formSliderValue}>{showValueText(input.value)}</span>
  </div>
);

const renderCheckbox = ({
  label,
  input
}) => (
  <FormControlLabel
    control={
      <Checkbox 
        color="primary" 
        className={style.formCheckbox}
        {...input}
      />
    }
    label={label}
  />
);

const renderMoreOptions = ({
  input
}) => (
  <a className={style.formMoreOptions} onClick={() => input.onChange(input.value ? false : true)}>{input.value ? "Less settings" : "More settings"}</a>
);

class SettingsForm extends React.Component {
  render() {
    const { handleSubmit, moreOptionsVisible } = this.props;
    return (
      <form
        onSubmit={handleSubmit}
        className={style.form}
      >
        <Field
          label="Items to Find"
          name="items"
          component={renderSlider}
          min={1}
          max={100}
          step={1}
          showValueText={(value) => {
            return (value > 1 ? `${value} items` : `${value} item`);
          }}
        />

        <Field
          label="Time Limit"
          name="time"
          component={renderSlider}
          min={1}
          max={60}
          step={1}
          showValueText={(value) => {
            return (value > 1 ? `${value} minutes` : `${value} minute`);
          }}
        />

        <Field
          name="options"
          component={renderMoreOptions}
        />

        <div className={style.formMoreOptionsContainer} style={{display: moreOptionsVisible ? "flex" : "none" }}>
          <Field
            label="Input custom items" 
            name="customItems"
            component={renderTextField}
            multiline
            placeholder="Enter each item on a newline"
          />

          <Field
            label="Use only custom items"
            name="customItemsOnly"
            component={renderCheckbox}
          />
        </div>

        <button className={`${style.btn_primary} ${style.formSubmit}`} type="submit">PLAY</button>
      </form>
    );
  }
}

const selector = formValueSelector('SVBSettingsForm');

SettingsForm = connect(state => ({
  moreOptionsVisible: selector(state, 'options'),
}))(SettingsForm);

SettingsForm = reduxForm({
  form: 'SVBSettingsForm',
  validate,
})(SettingsForm);

export default SettingsForm;