import React from 'react';
import { Button, Slider } from '@mui/material';
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";

import {WSConnection} from "../websocket";
import styles from "./style.module.scss";

const muiTheme = createTheme({
  overrides: {
    MuiSlider: {
      thumb: {
        color: "white",
        height: 20,
        borderRadius: 0
      },
      track: {
        color: "red",
        height: 10
      },
      rail: {
        color: "red",
        height: 10
      }
    }
  }
});

export default class  extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  
  render() {
    return (<>
      <div className={styles.pageH2}>Use the slider below to move the dragon's head, and the buttons to make it roar.</div>
      <div className={styles.dragonSliderDiv}>
        <ThemeProvider theme={muiTheme}>
          <Slider defaultValue={50} className={styles.dragonSlider} onChange={(event, pos) => WSConnection.sendMessage('"' + pos + '"')} />
        </ThemeProvider>
      </div>
      <div className={styles.dragonButtonsDiv}>
          <Button className={styles.dragonButtons} variant="contained" onClick={() => WSConnection.sendMessage("ROAR")}>Make the dragon roar!</Button>
          <Button className={styles.dragonButtons} variant="contained" onClick={() => WSConnection.sendMessage("LEAN")}>Make the dragon LEAN &amp; roar!</Button>
        </div>
    </>);
  }
}