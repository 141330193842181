import React from "react";
import { Link } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";

import { WSConnection } from "../websocket";
import { queueStatus, BackButton } from "../helpers";
import Diagram from "../assets/WindowChoice.svg";
import C1 from "../assets/NumberCircle-01.svg";
import C2 from "../assets/NumberCircle-02.svg";
import C3 from "../assets/NumberCircle-03.svg";
import C4 from "../assets/NumberCircle-04.svg";
import C5 from "../assets/NumberCircle-05.svg";
import styles from "./style.module.scss";

const Queues = [
  "PorchWindowLeft",
  "PorchWindowRight",
  "FrontDoorWindowTop",
  "FrontDoorWindowBottom",
  "GarageWindow",
];

export default class WindowPicker extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      queues: []
    };
  }

  componentDidMount() {
    WSConnection.initialize();
    WSConnection.bindListener(this);
    
    WSConnection.sendMessage({
      action: "leaveQueue",
    });
  }

  wsOpened = () => {
    WSConnection.sendMessage({
      action: "leaveQueue",
    });
  }

  messageReceived = (msg) => {
    if (msg && msg.length > 0) {
      this.setState({
        queues: msg.filter(queue => Queues.includes(queue.name)),
      });
    }
  }

  errorThrown(err) {

  }

  render() {
    const {queues} = this.state;

    const genButton = (queueName, queueNameFormatted, imgSrc, imgAlt) => {
      return (<>
        <Link to={`/halloween/window?name=${queueName}`}>
          <div className={styles.windowPickerButton}>
            <img src={imgSrc} alt={imgAlt}/>
            <div className={styles.buttonMiddle}>
              <div className={styles.buttonTitle}>{queueNameFormatted}</div>
              {queueStatus(queues, queueName)}
            </div>
            <ChevronRight/>
          </div>
        </Link>
      </>);
    };

    return (
      <>
        <div className={styles.windowPickerContainer}>
          <BackButton/>
          <h1 className={styles.windowPickerTitle}>Choose a Window to Draw on:</h1>
          <img src={Diagram}/>
          {genButton("PorchWindowLeft", "Left Porch Window", C1, "#1")}
          {genButton("PorchWindowRight", "Right Porch Window", C2, "#2")}
          {genButton("FrontDoorWindowTop", "Top Front Window", C3, "#3")}
          {genButton("FrontDoorWindowBottom", "Bottom Front Window", C4, "#4")}
          {genButton("GarageWindow", "Garage Window", C5, "#5")}
        </div>
      </>
    );
  }
}