import React from 'react';
import ReactRecaptcha from 'react-google-recaptcha';

export default class RecaptchaItem extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(result) {
    this.props.onChange(result); // notify the form after verified
  }

  render() {
    return (
      <ReactRecaptcha
        sitekey="6Lcv1KoZAAAAAJPhwKq2VmKeJgTyweVaRoA0ZIHa"
        theme="dark"
        onChange={this.onChange}
      />
    );
  }
}