import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {Helmet} from "react-helmet";

import QueuePage from "./queuePage";
import WindowPicker from './windowPicker';
import SkeletonPicker from './skeletonPicker';
import SkeletonControl from "./skeletonControl";
import ColorControl from "./colorControl";
import SpiderControl from "./spiderControl";
import WindowControl from "./windowControl";
import DragonControl from "./dragonControl";
import TreeControl from './treeControl';
import Admin from "./admin";
import NotFound from "pages/NotFound";

import WindowDraw from './assets/WindowDraw.svg';
import ChineseLantern from './assets/ChineseLantern.svg';
import Dragon from './assets/DragonControl.svg';
import LampPost from './assets/LampPost.svg';
import Spider from './assets/SpiderControl.svg';
import SkeletonTalking from './assets/SkeletonTalking.svg';
import ErrorWrapper from "./errorWrapper";
import styles from "./style.module.scss";

export default function HalloweenPageHandler() {
  const { page } = useParams();
  const query = new URLSearchParams(useLocation().search);
  let Page = null,
    Image = null,
    Title = page.split("").map((c, i) => i === 0 ? c.toUpperCase() : c).join(""),
    HeaderTitle = "",
    QueueWrapper = true,
    QueueName = null;

  switch (page) {
    case "skeletons":
      Page = <SkeletonPicker/>;
      Title = "Pick a Pirate";
      QueueWrapper = false;
      break;
    case "skeleton":
      Page = <SkeletonControl/>;
      Image = SkeletonTalking;
      if (query.get("name").toLowerCase() === "skeleton1") {
        Title = "Lower Graveyard Skeleton";
      }
      if (query.get("name").toLowerCase() === "skeleton2") {
        Title = "Lamppost Skeleton";
      }
      if (query.get("name").toLowerCase() === "skeleton3") {
        Title = "Driveway Skeleton";
      }
      HeaderTitle = "Talk Like a Pirate!";
      break;
    case "spiders":
      Page = <SpiderControl/>;
      Image = Spider;
      HeaderTitle = "Control the Spiders!";
      QueueName = "Spiders";
      break;
    case "treeColors":
      Page = <TreeControl/>;
      Image = ChineseLantern;
      HeaderTitle = "Turn the Tree Lanterns White!";
      Title = "Lanterns";
      QueueName = "Lanterns";
      break;
    case "lampposts":
      Page = <ColorControl/>;
      Image = LampPost;
      HeaderTitle = "Change the Lamp Post Colors!";
      QueueName = "Lampposts";
      break;
    case "windows":
      Page = <WindowPicker/>;
      Title = "Pick a Window";
      QueueWrapper = false;
      break;
    case "window":
      Page = <WindowControl/>;
      Image = WindowDraw;
      HeaderTitle = "Draw on the Windows!";
      Title = query.get("name").split("").map((c, i) => i === 0 ? c.toUpperCase() : c === c.toUpperCase() ? " " + c : c).join("");
      break;
    case "dragon":
      Page = <DragonControl/>;
      Image = Dragon;
      HeaderTitle = "Control the Dragon!";
      QueueName = "Dragon";
      break;
    case "admin":
      Page = <Admin/>
      Title = "Admin Page";
      QueueWrapper = false;
      break;
    default:
      Page = <NotFound/>;
      Title = "404";
      QueueWrapper = false;
      break;
  }

  return (<>
    <Helmet title={`${Title} - Skousen's Halloween 2021 Experience`}/>
    {QueueWrapper ? (
      <ErrorWrapper>
        <QueuePage image={Image} title={HeaderTitle} name={Title} queueName={query.get("name") || QueueName}>
          {Page}
        </QueuePage>
      </ErrorWrapper>
    ) : (
      <ErrorWrapper>
        {Page}
      </ErrorWrapper>
    )}
  </>)
}