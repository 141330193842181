import React from 'react';
import Cookies from 'universal-cookie';
import { Redirect } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';
import { PublishRounded, ArrowBackRounded, CheckCircleOutlineRounded } from '@mui/icons-material';

import ReleaseForm from './ReleaseForm';
import api from './API';
import Modal from 'components/Modal';
import styles from './style.module.scss';

const cookies = new Cookies();

export default class DanceCuts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accessToken: cookies.get('accessToken'),
      loadingUser: true,
      page: "home",
      release: {modal: {},},
    };
  }

  componentDidMount() {
    const {accessToken} = this.state;

    const code = new URL(window.location.href).searchParams.get("code");
    if (code) {
      api(`/login?code=${code}${process.env.REACT_APP_DEV ? "&dev=true" : ""}`, {
        method: 'POST',
      }, (err, json) => {
        if (json && json.accessToken) {
          cookies.set('accessToken', json.accessToken, {
              path: '/',
          });
          this.setState({
              redirect: "/dancecuts/admin",
              accessToken: json.accessToken,
          });
          this.getUser();
        }
        else {
          this.redirectToLogin();
        }
      });
    }
    else if (accessToken) {
      this.getUser();
    }
    else {
      this.redirectToLogin();
    }
  }

  getUser() {
    api('/user', {
      method: 'GET',
    }, (err, json) => {
      if (json && json.admin) {
        this.setState({
          loadingUser: false,
        });
        this.initLoad();
      }
      else {
        this.redirectToLogin();
      }
    });
  }

  initLoad() {
    api(`/release?os=windows`, {
      method: 'GET'
    }, (err, json) => {
      this.setState({
        release: {
          ...this.state.release,
          windowsLatest: (json && json.versionString) || "Unreleased",
        }
      });
    });
    api(`/release?os=mac`, {
      method: 'GET'
    }, (err, json) => {
      this.setState({
        release: {
          ...this.state.release,
          macLatest: (json && json.versionString) || "Unreleased",
        }
      });
    });
  }

  redirectToLogin() {
    cookies.remove("accessToken", {
        path: '/',
    });
    window.location = process.env.REACT_APP_DANCECUTS_LOGIN_URL;
  }

  onSubmitReleaseForm = (values) => {
    console.log(values);
    this.setState({
      release: {
        ...this.state.release,
        modal: {
          open: true,
          preventClose: true,
          loading: true,
          loadingStatus: "Uploading Windows release (this takes forever)...",
        },
      },
    });
    const {number, notes, winFile, macFile} = values;
    if (winFile) {
      this.uploadRelease('windows', number, notes, 'application/x-msdos-program', winFile[0], (success) => {
        if (success) {
          if (macFile) {
            this.setState({
              release: {
                ...this.state.release,
                modal: {
                  ...this.state.release.modal,
                  loadingStatus: "Uploading Mac release (this takes forever)...",
                },
              },
            });
            this.uploadRelease('mac', number, notes, 'application/zip', macFile[0], (success) => {
              if (success) {
                this.setState({
                  release: {
                    ...this.state.release,
                    modal: {
                      ...this.state.release.modal,
                      loading: false,
                      preventClose: false,
                      buttons: [
                        {
                          text: "Close",
                          onClick: () => {
                            this.setState({
                              page: "home",
                              release: {
                                ...this.state.release,
                                modal: {
                                  open: false,
                                },
                              },
                            });
                          },
                        },
                      ],
                    },
                  },
                });
              }
              else {
                this.setState({
                  release: {
                    ...this.state.release,
                    modal: {
                      ...this.state.release.modal,
                      loadingStatus: "Failed to upload the Mac release!",
                      preventClose: false,
                      buttons: [
                        {
                          text: "Close",
                          onClick: () => {
                            this.setState({
                              release: {
                                ...this.state.release,
                                modal: {
                                  open: false,
                                },
                              },
                            });
                          },
                        },
                      ],
                    },
                  },
                });
              }
            });
          }
          else {
            this.setState({
              release: {
                ...this.state.release,
                modal: {
                  ...this.state.release.modal,
                  loading: false,
                  preventClose: false,
                  buttons: [
                    {
                      text: "Close",
                      onClick: () => {
                        this.setState({
                          page: "home",
                          release: {
                            ...this.state.release,
                            modal: {
                              open: false,
                            },
                          },
                        });
                      },
                    },
                  ],
                },
              },
            });
          }
        }
        else {
          this.setState({
            release: {
              ...this.state.release,
              modal: {
                ...this.state.release.modal,
                loadingStatus: "Failed to upload the Windows release!",
                preventClose: false,
                buttons: [
                  {
                    text: "Close",
                    onClick: () => {
                      this.setState({
                        release: {
                          ...this.state.release,
                          modal: {
                            open: false,
                          },
                        },
                      });
                    },
                  },
                ],
              },
            },
          });
        }
      });
    }
    else if (macFile) {
      this.setState({
        release: {
          ...this.state.release,
          modal: {
            open: true,
            preventClose: true,
            loading: true,
            loadingStatus: "Uploading Mac release (this takes forever)...",
          },
        },
      });
      this.uploadRelease('mac', number, notes, 'application/zip', macFile[0], (success) => {
        if (success) {
          this.setState({
            release: {
              ...this.state.release,
              modal: {
                ...this.state.release.modal,
                loading: false,
                preventClose: false,
                buttons: [
                  {
                    text: "Close",
                    onClick: () => {
                      this.setState({
                        page: "home",
                        release: {
                          ...this.state.release,
                          modal: {
                            open: false,
                          },
                        },
                      });
                    },
                  },
                ],
              },
            },
          });
        }
        else {
          this.setState({
            release: {
              ...this.state.release,
              modal: {
                ...this.state.release.modal,
                loadingStatus: "Failed to upload the Mac release!",
                preventClose: true,
                buttons: [
                  {
                    text: "Close",
                    onClick: () => {
                      this.setState({
                        release: {
                          ...this.state.release,
                          modal: {
                            open: false,
                          },
                        },
                      });
                    },
                  },
                ],
              },
            },
          });
        }
      });
    }
  }

  uploadRelease(os, number, notes, contentType, file, callback) {
    api(`/release?os=${os}&version=${number}`, {
      method: 'PUT',
      body: {
        notes,
      },
    }, (err, json) => {
      if (json && json.url) {
        console.log("Putting...");
        fetch(json.url, {
          method: 'PUT',
          headers: {
            'Content-Type': contentType,
          },
          body: file,
        })
        .then(response => {
          console.log("RESPONSE: ", response);
          if (response.status === 200) {
            return callback(true);
          }
          else {
            return callback(false);
          }
        });
      }
    });
  }

  render() {
    const {loadingUser, redirect, page, release } = this.state;
    return (
      loadingUser ? (
        <div className="centeredContainer">
          <CircularProgress className={styles.loader}/>
        </div>
      ) : (
        <div className={styles.wrapper}>
          <div className={`centeredContainerHorz ${page === "home" ? styles.divInLeft : styles.divOutLeft}`}>
            {redirect ? <Redirect to={redirect}/> : null}
            <h1>DanceCuts Admin Hub</h1>
            <button 
              className={styles.btn}  
              onClick={() => this.setState({page: "release"})}
              variant="contained"
            >
              <PublishRounded/>
              Release Update for DanceCuts
            </button>
          </div>
          <div className={`centeredContainerHorz ${page === "release" ? styles.divInRight : styles.divOutRight}`}>
            <div className={styles.header}>
              <BackBtn onClick={() => this.setState({page:"home"})}/>
              <h1>New DanceCuts Release</h1>
            </div>
            <ReleaseForm
              onSubmit={this.onSubmitReleaseForm}
              loading={false}
              windowsLatest={release.windowsLatest}
              macLatest={release.macLatest}
            />
            <Modal
              title="Deploying release..."
              visible={release.modal.open}
              buttons={release.modal.buttons}
              onClose={() => {
                release.modal.open = false;
              }}
              preventClose={release.modal.preventClose}
            >
              {release.modal.loading ? (
                <div className={styles.modalRelease}>
                  {!release.modal.loadingStatus.startsWith("Failed") ? <CircularProgress className={styles.loader}/> : null}
                  <p>{release.modal.loadingStatus}</p>
                </div>
              ) : (
                <div className={styles.modalRelease}>
                  <CheckCircleOutlineRounded/>
                  <p>Successfully deployed!</p>
                </div>
              )}
            </Modal>
          </div>
        </div>
      )
    )
  }
};

class BackBtn extends React.Component {
  render() {
    const {onClick} = this.props;
    return (
      <button className={styles.btnBack} onClick={onClick}>
        <ArrowBackRounded />
      </button>
    );
  }
}