import React from 'react';
import { Link } from 'react-router-dom';

import {SetFavicon} from 'utils';
import testTube from "./test_tube.png";
import scroll from "./scroll.png";
import programmer from "./programmer.png";
import cardIndex from "./card_index.png";
import styles from './style.module.scss';

export default function Home() {
  SetFavicon();

  return (
    <div className="centeredContainer">
      <div className={styles.container}>
        <h1 className={styles.title}>Jo the Dev</h1>
        <h2 className={styles.link}><Link to="/projects"><span className="bullet">>_</span> Projects <img alt="test tube emoji" src={testTube}></img></Link></h2>
        <h2 className={styles.link}><Link to="/blog"><span className="bullet">>_</span> Blog <img alt="scroll emoji" src={scroll}></img></Link></h2>
        <h2 className={styles.link}><Link to="/about"><span className="bullet">>_</span> About <img alt="developer emoji" src={programmer}></img></Link></h2>
        <h2 className={styles.link}><Link to="/contact"><span className="bullet">>_</span> Contact <img alt="card index emoji" src={cardIndex}></img></Link></h2>
      </div>
    </div>
  );
}