import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function api(endpoint, options, callback) {
  const accessToken = cookies.get('accessToken');
  options.headers = options.headers || {};
  options.headers["Content-Type"] = "application/json";
  if (accessToken) {
    options.headers.Authorization = `Bearer ${accessToken}`;
  }
  if (typeof options.body !== "string") {
    options.body = JSON.stringify(options.body);
  }
  fetch("https://adminapi.dancecuts.com/prod" + endpoint, options)
  .then(response => response.json())
  .then(json => {
    console.log(json);
    if (json) {
      if (json.success) {
        return callback(null, json);
      }
      return callback(json.errorMessage || "Unknown error");
    }
    else {
      return callback("Unknown error");
    }
  });
}