import React from 'react';
import { useParams } from 'react-router-dom';
import {Helmet} from "react-helmet";

import manifest from 'pages/projects/manifest';
import {SetFavicon} from 'utils';
import NotFound from 'pages/NotFound';

export default function ProjectLoader () {
  const { id, subPage } = useParams();
  for (var i = 0; i < manifest.length; i++) {
    var proj = manifest[i];
    if (proj.url === id) {
      if (subPage) {
        for (var j = 0; j < proj.subPages?.length; j++) {
          if (proj.subPages[j] === subPage) {
            const App = require(`pages/projects/${proj.folder}/${proj.subPages[j]}`).default;
            SetFavicon(require(`pages/projects/${proj.folder}${proj.favicon}`));
            return (
              <>
                <Helmet title={proj.name}/>
                <App/>
              </>
            );
          }
        }
      }
      else {
        const App = require(`pages/projects/${proj.folder}${proj.app}`).default;
        SetFavicon(require(`pages/projects/${proj.folder}${proj.favicon}`));
        return (
          <>
            <Helmet title={proj.name}/>
            <App/>
          </>
        );
      }
    }
  }
  SetFavicon();
  return (
    <>
      <Helmet title="404"/>
      <NotFound/>
    </>
  );
}