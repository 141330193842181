import React, { Component } from "react";
import * as d3 from 'd3';
import { Link } from 'react-router-dom';

import {SetFavicon} from 'utils';

import styles from './style.module.scss';

class NotFound extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", () => {
      this.setState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    });

    d3.select(`#stop1`)
      .transition()
      .duration(1500)
      .style("stop-color", '#E90075');

    d3.select(`#stop2`)
      .transition()
      .duration(1500)
      .style("stop-color", '#FF9A3F');

    d3.select(`#sun`)
      .transition()
      .duration(1500)
      .style("transform", 'translate(50%,100vh) scale(.5)');

    d3.select(`#text`)
      .transition()
      .duration(1500)
      .style('opacity', '100%');

    SetFavicon();
  }

  render() {
    const {width, height} = this.state;
    return (
      <div className={`centeredContainer ${styles.center}`}>
        <svg className={styles.svg} x="0px" y="0px">
          <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1={width / 2} y1="0" x2={width / 2} y2={height}>
            <stop id="stop1" offset="0" style={{'stop-color':'#3f7aff'}}/>
            <stop id="stop2" offset="0.9109" style={{'stop-color':'#4200e9'}}/>
          </linearGradient>
          
          <rect width={width} height={height} style={{fill:'url(#SVGID_1_)'}}/>
          <circle id="sun" className={styles.sun} style={{transform: 'translate(50%, -10vh) scale(.5)'}} r="300"/>
        </svg>
        <div id="text" style={{opacity: '0%'}} className={styles.text}>
          <h1 className={styles.goodbye}>I've said goodbye to this page.</h1>
          <h2 className={styles.exist}>(Or it never existed.)</h2>
          <h3 className={styles.link}><Link to="/" className="link"><u>- Jo the Dev</u></Link></h3>
        </div>
      </div>
    );
  }
}

export default NotFound;